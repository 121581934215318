import _ from "lodash";
import {
  days,
  compressors,
  economizers,
  fans,
  heatings,
  buildings,
  windows,
  orientations,
  climates,
  zones,
} from "rtucc-common/constants";

const isConsecutive = (values) => Boolean(values.reduce((p, c) => (p + 1 === c ? c : false)));

const formatTime = (time) => (time < 12 ? `${time} am` : `${time - 12} pm`);

/**
 * Build a string representation of a schedule.
 *
 * @param {*} schedule
 * @returns a schedule string
 */
export const buildScheduleLabel = (schedule) => {
  try {
    return days
      .values()
      .map((d) => ({ day: d, start: schedule[`${d.name}Start`], end: schedule[`${d.name}End`] }))
      .reduce((p, c) => {
        const i = _.findIndex(p, (n) => n.start === c.start && n.end === c.end);
        if (i === -1) {
          p.push({ days: [c.day], start: c.start, end: c.end });
        } else {
          p[i].days.push(c.day);
        }
        return p;
      }, [])
      .map(
        (n) =>
          `${
            n.days.length > 1 && isConsecutive(n.days.map((d) => d.index))
              ? `${n.days[0].abbr} - ${n.days[n.days.length - 1].abbr}`
              : n.days.map((d) => d.abbr).join(", ")
          } ${formatTime(n.start)} - ${formatTime(n.end)}`
      )
      .join(", ");
  } catch (error) {
    return "Invalid schedule";
  }
};

/**
 * Build a string representation of a RTUC.
 * @param {*} rtuc
 * @param {string} field suffix
 * @returns a RTUC string
 */
export const buildRtucLabel = (rtuc, suffix = "") => {
  const {
    [`compressor${suffix}`]: compressor,
    [`numberOfSpeeds${suffix}`]: numberOfSpeeds,
    [`fan${suffix}`]: fan,
    [`eer${suffix}`]: eer,
    [`heating${suffix}`]: heating,
    [`heatingCop${suffix}`]: heatingCop,
    [`minOaFraction${suffix}`]: minOaFraction,
    [`economizer${suffix}`]: economizer,
    [`maximumLimitDryBulb${suffix}`]: maximumLimitDryBulb,
    [`capitalCost${suffix}`]: capitalCost,
  } = rtuc;
  const compressorType = compressors.parse(compressor);
  const numberOfSpeedsAlt = compressorType.name === "MultiSpeed" ? ` (${numberOfSpeeds})` : "";
  const fanType = fans.parse(fan);
  const heatingType = heatings.parse(heating);
  const economizerType = economizers.parse(economizer);
  const maximumLimitDryBulbAlt = economizerType.name === "DifferentialDryBulb" ? ` (${maximumLimitDryBulb})` : "";
  try {
    return `${suffix}: 
    ${compressorType.label} Compressor${numberOfSpeedsAlt}, 
    ${fanType.label} Fan, 
    EER ${eer}:1, 
    ${heatingType.label} Heating, 
    COP ${heatingCop}, 
    Min OA Fraction ${minOaFraction}, 
    ${economizerType.label}${maximumLimitDryBulbAlt},
    $${parseInt(capitalCost).toLocaleString()}`;
  } catch (error) {
    return "Invalid RTUC";
  }
};

/**
 * Build a string representation of a calculation.
 * @param {*} calculation
 * @returns a calculation string
 */
export const buildCalculationLabel = (calculation) => {
  const {
    label,
    building,
    zone,
    window,
    orientation,
    climate,
    coolingSetpoint,
    coolingSetback,
    heatingSetpoint,
    heatingSetback,
    oversizeFactor,
    blendedElectricityRate,
    blendedNaturalGasRate,
    realDiscountRate,
    rtuLifetime,
  } = calculation;
  const buildingType = buildings.parse(building);
  const zoneType = zones.parse(zone);
  const windowType = windows.parse(window);
  const orientationType = orientations.parse(orientation);
  const climateType = climates.parse(climate);
  const labelAlt = _.isEmpty(label) ? "" : `${label}: `;
  const oversizeFactorAlt = parseFloat(oversizeFactor) > 1.0 ? `, Oversize ${oversizeFactor}` : "";
  try {
    return `${labelAlt}
    ${buildingType.label} (${zoneType.label}),  
    ${windowType.label} Windows, 
    ${orientationType.label} Facing, 
    ${climateType.label}, 
    Cooling ${coolingSetpoint}°F ${coolingSetback}°F, 
    Heating ${heatingSetpoint}°F ${heatingSetback}°F
    ${oversizeFactorAlt},
    Electricity $${blendedElectricityRate}/kWh,
    Natural Gas $${blendedNaturalGasRate}/ccf,
    Discount ${realDiscountRate}%,
    Lifetime ${rtuLifetime} years`;
  } catch (error) {
    return "Invalid Calculation";
  }
};
