import { parseBoolean } from "utils/utils";
import { doMocked, isMocked, read } from "../api";
import { key } from "./action";
export const SERVICE_ENDPOINT = "text";
export const SERVICE_ENDPOINT_READ_TEXT = `${SERVICE_ENDPOINT}/read`;

export const readText = () => {
  if (isMocked()) return doMocked(key, SERVICE_ENDPOINT_READ_TEXT);
  return read(`${SERVICE_ENDPOINT}`, null, parseBoolean(process.env.REACT_APP_LOGIN));
};
