import { AppBar, Menu, MenuItem, Toolbar, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { AccountCircle as AccountCircleIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from "@material-ui/icons";
import { MuiIconButton, MuiLogin } from "components";
import { primary, primaryTint } from "constants/palette";
import { selectMode, setMode } from "controllers/common/action";
import { loginUser, logoutUser, selectLoginUserRequest, selectUser } from "controllers/user/action";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { parseBoolean } from "utils/utils";
import styles from "./styles";

const isGuestLogin = parseBoolean(process.env.REACT_APP_GUEST_LOGIN);

class MuiHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { anchorEl: null, dialog: false };
  }

  handleOpen = (type) => (event) => {
    this.setState({ type: type, anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ type: null, anchorEl: null });
  };

  handleMode = (selected) => {
    const { mode } = this.props;
    this.props.setMode({ ...mode, mode: selected });
    this.handleClose();
  };

  handleLogout = () => {
    const { history } = this.props;
    this.handleClose();
    history.push({
      search: "",
    });
    this.props.logoutUser();
  };

  handleLogin = () => {
    this.handleClose();
    this.setState({ dialog: true });
  };

  handleGuest = () => {
    this.handleClose();
    this.props.loginUser({ email: process.env.REACT_APP_GUEST_EMAIL, password: process.env.REACT_APP_GUEST_PASSWORD });
  };

  handleDialog = () => {
    this.setState({ dialog: false });
  };

  renderMenuItems() {
    const { user } = this.props;
    const items = [];
    if (user) {
      items.push(
        <MenuItem key="logout" onClick={this.handleLogout}>
          Logout
        </MenuItem>
      );
    } else {
      items.push(
        <MenuItem key="login" onClick={this.handleLogin}>
          Login as User
        </MenuItem>
      );
      if (isGuestLogin) {
        items.push(
          <MenuItem key="guest" onClick={this.handleGuest}>
            Login as Guest
          </MenuItem>
        );
      }
    }
    return items;
  }

  render() {
    const { classes, page, request, user, mode } = this.props;
    const { anchorEl, type, dialog } = this.state;
    const login = parseBoolean(process.env.REACT_APP_LOGIN);
    const username = user ? user.name : request ? request.email : "Unknown";
    return (
      <AppBar position="fixed" className={classes.root}>
        {dialog && <MuiLogin noContinue onClose={this.handleDialog} />}
        <Toolbar variant="dense" style={{ backgroundColor: primary }} className={classes.toolbar}>
          <Link className={classes.link} to="/">
            <Typography variant="h6" className={classes.title}>
              {process.env.REACT_APP_TITLE}
            </Typography>
          </Link>
          {/* <img
            className={classes.image}
            src="images/App_Logo_Color.png"
            alt={process.env.REACT_APP_TITLE}
          /> */}
          <Typography variant="h6" className={classes.page}>
            {page.label}
          </Typography>
          {mode && (
            <Fragment>
              <Typography variant="h6" className={classes.mode}>
                {mode.mode.label}
              </Typography>
              <MuiIconButton color={primaryTint} onClick={this.handleOpen("mode")}>
                <KeyboardArrowDownIcon />
              </MuiIconButton>
              <Menu
                id="mode-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={type === "mode"}
                onClose={this.handleClose}
              >
                {mode.modes.map((m) => (
                  <MenuItem
                    key={`mode-${m.name}`}
                    selected={m.name === mode.mode.name}
                    onClick={() => this.handleMode(m)}
                  >
                    {m.label}
                  </MenuItem>
                ))}
              </Menu>
            </Fragment>
          )}
          <Typography variant="h6" className={classes.spacer}></Typography>
          {login && (
            <Fragment>
              <AccountCircleIcon />
              <Typography variant="h6" className={classes.user}>
                {user ? username : "Log in"}
              </Typography>
              <MuiIconButton color="inherit" onClick={this.handleOpen("user")}>
                <KeyboardArrowDownIcon />
              </MuiIconButton>
              <Menu
                id="user-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                keepMounted
                open={type === "user"}
                onClose={this.handleClose}
              >
                {this.renderMenuItems()}
              </Menu>
            </Fragment>
          )}
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = (state) => ({
  request: selectLoginUserRequest(state),
  user: selectUser(state),
  mode: selectMode(state),
});

const mapActionToProps = {
  loginUser,
  logoutUser,
  setMode,
};

export default connect(mapStateToProps, mapActionToProps)(withRouter(withStyles(styles)(MuiHeader)));
