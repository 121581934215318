import { InputAdornment, TextField, Tooltip } from "@material-ui/core";

import { ConditionalWrapper } from "utils/utils";
import MuiLabel from "./MuiLabel";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import clsx from "clsx";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";

const getFaultName = (props) => {
  const { classes, error, warning, info } = props;
  if (error) {
    return classes.error;
  } else if (warning) {
    return classes.warning;
  } else if (info) {
    return classes.info;
  }
};

const MuiTextField = (props) => {
  const {
    classes,
    className,
    header,
    tooltip,
    adornment,
    position,
    required,
    placeholder,
    "aria-label": ariaLabel,
  } = props;

  return (
    <ConditionalWrapper
      condition={Boolean(header)}
      wrapper={(c) => (
        <div>
          <MuiLabel header={header} required={required} />
          {c}
        </div>
      )}
    >
      <Tooltip placement="bottom" title={tooltip ? tooltip : ""}>
        <TextField
          {..._.omit(props, [
            "classes",
            "className",
            "header",
            "tooltip",
            "placeholder",
            "adornment",
            "info",
            "warning",
            "position",
          ])}
          className={clsx(className, classes.textField, getFaultName(props))}
          aria-label={ariaLabel ? ariaLabel : header ? header : placeholder}
          size="medium"
          variant="outlined"
          fullWidth
          placeholder={placeholder}
          InputProps={{
            ...(adornment &&
              position === "start" && {
                startAdornment: adornment && <InputAdornment position="start">{adornment}</InputAdornment>,
              }),
            ...(adornment &&
              position !== "start" && {
                endAdornment: adornment && <InputAdornment position="end">{adornment}</InputAdornment>,
              }),
          }}
        />
      </Tooltip>
    </ConditionalWrapper>
  );
};

MuiTextField.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string,
  adornment: PropTypes.oneOfType([PropTypes.bool, PropTypes.element, PropTypes.string]),
  position: PropTypes.oneOf(["start", "end"]),
  required: PropTypes.bool,
};

export default withStyles(styles)(MuiTextField);
