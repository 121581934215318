import {
  CREATE_CALCULATION,
  FETCH_CALCULATION,
  FETCH_CALCULATIONS,
  REMOVE_CALCULATION,
  UPDATE_CALCULATION,
  createCalculationBusy,
  createCalculationError,
  createCalculationSuccess,
  fetchCalculationBusy,
  fetchCalculationError,
  fetchCalculationSuccess,
  fetchCalculationsBusy,
  fetchCalculationsError,
  fetchCalculationsSuccess,
  removeCalculationBusy,
  removeCalculationError,
  removeCalculationSuccess,
  updateCalculationBusy,
  updateCalculationError,
  updateCalculationSuccess,
  fetchCalculation,
  fetchCalculations,
  selectCalculationRequest,
  sortCalculationsBusy,
  sortCalculationsError,
  selectCalculations,
  sortCalculationsSuccess,
  SORT_CALCULATIONS,
  selectSortCalculationsRequest,
  sortCalculations,
  setActive,
} from "./action";
import { call, put, takeEvery, takeLatest, select, delay } from "redux-saga/effects";
import { createCalculation, readCalculation, readCalculations, removeCalculation, updateCalculation } from "./api";

import { ActionTypes } from "../util";
import { BUSY_GLOBAL } from "../busy/action";
import { filter, logError } from "utils/utils";
import moment from "moment";
import stages from "constants/stage";
import _ from "lodash";
const { REQUEST } = ActionTypes;

export function* createCalculationSaga(action) {
  const payload = action.payload ? action.payload : {};
  try {
    yield put(createCalculationBusy(BUSY_GLOBAL));
    yield put(createCalculationError());
    const response = yield call(createCalculation, payload);
    yield put(createCalculationSuccess(response));
    yield put(setActive(response.uuid));
    yield call(readCalculationSaga, fetchCalculation({ uuid: response.uuid }));
    yield call(readCalculationsSaga, fetchCalculations());
  } catch (error) {
    logError(error);
    yield put(createCalculationError(error.message));
  } finally {
    yield put(createCalculationBusy(false));
  }
}

export function* readCalculationSaga(action) {
  const payload = action.payload ? action.payload : {};
  const { uuid } = payload;
  try {
    yield put(fetchCalculationBusy(true));
    yield put(fetchCalculationError());
    let response;
    if (uuid) {
      response = yield call(readCalculation, uuid);
    }
    yield put(fetchCalculationSuccess(response));
  } catch (error) {
    logError(error);
    yield put(fetchCalculationError(error.message));
  } finally {
    yield put(fetchCalculationBusy(false));
  }
}

export function* removeCalculationSaga(action) {
  const payload = action.payload ? action.payload : {};
  const { id } = payload;
  try {
    yield put(removeCalculationBusy(true));
    yield put(removeCalculationError());
    yield call(removeCalculation, id);
    yield put(removeCalculationSuccess(true));
    yield call(readCalculationsSaga, fetchCalculations());
  } catch (error) {
    logError(error);
    yield put(removeCalculationError(error.message));
  } finally {
    yield put(removeCalculationBusy(false));
  }
}

export function* updateCalculationSaga(action) {
  const payload = action.payload ? action.payload : {};
  const { id } = payload;
  try {
    yield put(updateCalculationBusy(true));
    yield put(updateCalculationError());
    const response = yield call(updateCalculation, id, payload);
    yield put(updateCalculationSuccess(response));
    const request = yield select(selectCalculationRequest);
    yield call(readCalculationSaga, fetchCalculation(request));
    yield call(readCalculationsSaga, fetchCalculations());
  } catch (error) {
    logError(error);
    yield put(updateCalculationError(error.message));
  } finally {
    yield put(updateCalculationBusy(false));
  }
}

export function* readCalculationsSaga() {
  try {
    yield put(fetchCalculationsBusy(true));
    yield put(fetchCalculationsError());
    const response = yield call(readCalculations);
    yield put(fetchCalculationsSuccess(response));
    const request = yield select(selectSortCalculationsRequest);
    yield call(sortCalculationsSaga, sortCalculations(request));
  } catch (error) {
    logError(error);
    yield put(fetchCalculationsError(error.message));
  } finally {
    yield put(fetchCalculationsBusy(false));
  }
}

export const transformCalculations = (calculations) => {
  if (!Array.isArray(calculations)) {
    return calculations;
  }
  return calculations.map((c) => _.merge(c, { stage: stages.parse(c.stage).label }));
};

export const filterSortCalculations = (calculations, field, direction, search) => {
  if (!Array.isArray(calculations)) {
    return calculations;
  }
  let result = calculations.slice();
  if (field && direction) {
    let comparator;
    switch (direction) {
      case "asc":
        switch (field) {
          case "createdAt":
          case "updatedAt":
            comparator = (a, b) => moment(a[field]).valueOf() - moment(b[field]).valueOf();
            break;
          default:
            comparator = (a, b) => a[field] && a[field].localeCompare(b[field]);
        }
        break;
      default:
        switch (field) {
          case "createdAt":
          case "updatedAt":
            comparator = (a, b) => moment(b[field]).valueOf() - moment(a[field]).valueOf();
            break;
          default:
            comparator = (a, b) => b[field] && b[field].localeCompare(a[field]);
        }
        break;
    }
    result = result.sort(comparator);
  }
  return filter(result, search, ["label", "stage", "id", "uuid"]);
};

export function* sortCalculationsSaga(action) {
  const { payload } = action;
  const { field, direction, search } = payload ? payload : {};
  try {
    yield delay(parseInt(process.env.REACT_APP_DEBOUNCE));
    yield put(sortCalculationsBusy(true));
    yield put(sortCalculationsError());
    const calculations = yield select(selectCalculations);
    const response = yield call(filterSortCalculations, transformCalculations(calculations), field, direction, search);
    yield put(sortCalculationsSuccess(response));
  } catch (error) {
    logError(error);
    yield put(sortCalculationsError(error.message));
  } finally {
    yield put(sortCalculationsBusy(false));
  }
}

export default function* calculationsSaga() {
  yield takeEvery(CREATE_CALCULATION[REQUEST], createCalculationSaga);
  yield takeLatest(FETCH_CALCULATIONS[REQUEST], readCalculationsSaga);
  yield takeLatest(FETCH_CALCULATION[REQUEST], readCalculationSaga);
  yield takeEvery(REMOVE_CALCULATION[REQUEST], removeCalculationSaga);
  yield takeEvery(UPDATE_CALCULATION[REQUEST], updateCalculationSaga);
  yield takeLatest(SORT_CALCULATIONS[REQUEST], sortCalculationsSaga);
}
