import { MuiStepper } from "components";
import React from "react";
import _ from "lodash";
import stages from "constants/stage";
import { parseBoolean } from "utils/utils";
import { Loop as LoopIcon } from "@material-ui/icons";

class Home extends React.Component {
  getStep() {
    const { stage } = this.props;
    const step = _.get(stages.parse(stage), ["index"], 0);
    return step;
  }

  getSteps() {
    const temp = stages.values().map((v) => ({ name: v.label, icon: v.icon, variant: v.variant }));
    if (parseBoolean(process.env.REACT_APP_ALLOW_REPROCESS)) {
      temp.push({ name: "Reprocess", icon: LoopIcon, variant: "primary" });
    }
    return temp;
  }

  getDisabled() {
    const { valid } = this.props;
    const step = this.getStep();
    return _.concat([], valid && step === 1 ? [] : [2]);
  }

  isStepBelow() {
    const step = this.getStep();
    switch (step) {
      case 0:
        return false;
      default:
        return true;
    }
  }

  isStepAbove() {
    const { valid } = this.props;
    const step = this.getStep();
    switch (step) {
      case 0:
        return true;
      case 1:
        return valid;
      case 4:
      case 5:
        return parseBoolean(process.env.REACT_APP_ALLOW_REPROCESS);
      default:
        return false;
    }
  }

  render() {
    const { classes, onStepChange, backLabel, nextLabel } = this.props;
    return (
      <div className={classes.header}>
        <MuiStepper
          steps={this.getSteps()}
          step={this.getStep()}
          // disabled={this.getDisabled()}
          onStepChange={onStepChange}
          isStepBelow={this.isStepBelow()}
          isStepAbove={this.isStepAbove()}
          backLabel={backLabel}
          nextLabel={nextLabel}
          alternativeLabel
        />
      </div>
    );
  }
}

export default Home;
