import { Error as ErrorIcon } from "@material-ui/icons";
import Root from "./Root";

const route = {
  sequence: 1000,
  name: "NotFound",
  label: "Not Found",
  path: undefined,
  hidden: true,
  component: Root,
  icon: ErrorIcon,
};

export default route;
