import _ from "lodash";
import Type from "./type";
import { Calculate as CalculateIcon } from "icons";
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Loop as LoopIcon,
  Queue as QueueIcon,
  AddCircle as AddCircleIcon,
} from "@material-ui/icons";
import { stages } from "rtucc-common/constants";

class Stage extends Type {
  values = () =>
    _.cloneDeep([
      _.defaults(stages.parse("new"), {
        name: "new",
        label: "New",
        icon: AddCircleIcon,
        variant: "primary",
      }),
      _.defaults(stages.parse("create"), {
        name: "create",
        label: "Created",
        icon: CalculateIcon,
        variant: "primary",
      }),
      _.defaults(stages.parse("queue"), {
        name: "queue",
        label: "Queued",
        icon: QueueIcon,
        variant: "primary",
      }),
      _.defaults(stages.parse("process"), {
        name: "process",
        label: "Processing",
        icon: LoopIcon,
        variant: "primary",
      }),
      _.defaults(stages.parse("fail"), {
        name: "fail",
        label: "Failed",
        icon: ErrorIcon,
        variant: "error",
      }),
      _.defaults(stages.parse("complete"), {
        name: "complete",
        label: "Completed",
        icon: CheckCircleIcon,
        variant: "verified",
      }),
    ]).map((v, i) => _.merge({}, v, { index: i }));
}

export default new Stage();
