import validator from "validator";
import { calculations, schedules } from "rtucc-common/constants";
import _ from "lodash";

/**
 * isValid - validates by type
 * Case for types email, username, name, and tagname
 * * value length has to be greater than 2
 * * value has to be unique
 * @param {string} type
 * @param {string} value
 * @param {string[]} values
 */
const isValid = (type, value, values) => {
  switch (type) {
    case "email":
      return validator.isEmail(`${value}`) && (Array.isArray(values) ? !values.includes(value) : true);
    case "username":
    case "name":
      return validator.isLength(`${value}`, { min: 2 }) && (Array.isArray(values) ? !values.includes(value) : true);
    case "text":
      return validator.isLength(`${value}`, { min: 1 });
    case "ssid":
      return validator.isLength(`${value}`, { min: 1, max: 32 });
    case "password":
      return (
        validator.isLength(`${value}`, { min: 8 }) &&
        validator.matches(`${value}`, /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9\s]).*$/)
      );
    case "url":
      return validator.isURL(`${value}`);
    case "description":
      return (
        value !== undefined &&
        (validator.isEmpty(`${value}`, { ignore_whitespace: true }) || validator.isLength(`${value}`, { min: 0 }))
      );
    case "ip":
      return validator.isIP(`${value}`) && (Array.isArray(values) ? !values.includes(value) : true);
    case "coolingSetpoint":
      return validator.isFloat(`${value}`, _.get(calculations.parse(type), ["validate"], {}));
    case "coolingSetback":
      return validator.isFloat(`${value}`, _.get(calculations.parse(type), ["validate"], {}));
    case "heatingSetpoint":
      return validator.isFloat(`${value}`, _.get(calculations.parse(type), ["validate"], {}));
    case "heatingSetback":
      return validator.isFloat(`${value}`, _.get(calculations.parse(type), ["validate"], {}));
    case "oversizeFactor":
      return validator.isFloat(`${value}`, _.get(calculations.parse(type), ["validate"], {}));
    case "coolingCapacity":
      return validator.isFloat(`${value}`, _.get(calculations.parse(type), ["validate"], {}));
    case "blendedElectricityRate":
      return validator.isFloat(`${value}`, _.get(calculations.parse(type), ["validate"], {}));
    case "blendedNaturalGasRate":
      return validator.isFloat(`${value}`, _.get(calculations.parse(type), ["validate"], {}));
    case "realDiscountRate":
      return validator.isInt(`${value}`, _.get(calculations.parse(type), ["validate"], {}));
    case "rtuLifetime":
      return validator.isInt(`${value}`, _.get(calculations.parse(type), ["validate"], {}));
    case "numberOfSpeeds":
      return validator.isInt(`${value}`, _.get(calculations.parse(type), ["validate"], {}));
    case "eer":
      return validator.isFloat(`${value}`, _.get(calculations.parse(type), ["validate"], {}));
    case "heatingCop":
      return validator.isFloat(`${value}`, _.get(calculations.parse(type), ["validate"], {}));
    case "minOaFraction":
      return validator.isFloat(`${value}`, _.get(calculations.parse(type), ["validate"], {}));
    case "maximumLimitDryBulb":
      return validator.isFloat(`${value}`, _.get(calculations.parse(type), ["validate"], {}));
    case "capitalCost":
      return validator.isInt(`${value}`, _.get(calculations.parse(type), ["validate"], {}));
    case "dayStart": {
      const { min, max } = _.get(schedules.parse("mondayStart"), ["validate"], {});
      return validator.isInt(`${value}`, { min: min, max: max });
    }
    case "dayEnd": {
      const { min, max } = _.get(schedules.parse("mondayEnd"), ["validate"], {});
      return validator.isInt(`${value}`, { min: min - 12, max: max - 12 });
    }
    default:
      return true;
  }
};

const getMessage = (type, value, values) => {
  if (isValid(type, value, values)) {
    return undefined;
  }
  switch (type) {
    case "email":
      if (!validator.isEmail(`${value}`)) {
        return "Must be a valid email address.";
      } else if (Array.isArray(values) ? values.includes(value) : true) {
        return "An account with this email address already exists.";
      } else {
        return "Must be a valid email address.";
      }
    case "username":
      if (!validator.isLength(`${value}`, { min: 2 })) {
        return "Must be at least 2 characters long.";
      } else if (Array.isArray(values) && values.includes(value)) {
        return "An account with this username already exists.";
      } else {
        return "Must be a valid username.";
      }
    case "name":
      if (!validator.isLength(`${value}`, { min: 2 })) {
        return "Must be at least 2 characters long.";
      } else if (Array.isArray(values) && values.includes(value)) {
        return "An account with this name already exists.";
      } else {
        return "Must be a valid name.";
      }
    case "text":
      if (!validator.isLength(`${value}`, { min: 1 })) {
        return "Must be at least 1 character long.";
      } else {
        return "Must enter text in this field.";
      }
    case "ssid":
      if (!validator.isLength(`${value}`, { min: 1 })) {
        return "Must enter SSID in this field.";
      } else if (!validator.isLength(`${value}`, { max: 32 })) {
        return "SSID can be a maximum of 32 characters.";
      } else {
        return "Must enter SSID in this field.";
      }
    case "password":
      if (!validator.isLength(`${value}`, { min: 8 })) {
        return "Must be at least 8 characters long.";
      } else if (!validator.matches(`${value}`, /[a-z]/)) {
        return "Must contain a lowercase letter.";
      } else if (!validator.matches(`${value}`, /[A-Z]/)) {
        return "Must contain an uppercase letter.";
      } else if (!validator.matches(`${value}`, /[0-9]/)) {
        return "Must contain a number.";
      } else if (!validator.matches(`${value}`, /[^a-zA-Z0-9\s]/)) {
        return "Must contain a special character.";
      } else {
        return "Must be a valid password.";
      }
    case "url":
      return "Must be a valid URL.";
    case "ip":
      return "Must be a valid IP address.";
    case "coolingSetpoint": {
      const { min, max } = _.get(calculations.parse(type), ["validate"], {});
      return `Cooling setpoint must be a floating point value from ${min} to ${max}.`;
    }
    case "coolingSetback": {
      const { min, max } = _.get(calculations.parse(type), ["validate"], {});
      return `Cooling setback must be a floating point value from ${min} to ${max}.`;
    }
    case "heatingSetpoint": {
      const { min, max } = _.get(calculations.parse(type), ["validate"], {});
      return `Heating setpoint must be a floating point value from ${min} to ${max}`;
    }
    case "heatingSetback": {
      const { min, max } = _.get(calculations.parse(type), ["validate"], {});
      return `Heating setback must be a floating point value from ${min} to ${max}`;
    }
    case "oversizeFactor": {
      const { min, max } = _.get(calculations.parse(type), ["validate"], {});
      return `Oversize factor must be a floating point value from ${min} to ${max}`;
    }
    case "coolingCapacity": {
      const { min, max } = _.get(calculations.parse(type), ["validate"], {});
      return `Cooling capacity must be a floating point value from ${min} to ${max}`;
    }
    case "blendedElectricityRate": {
      const { min, max } = _.get(calculations.parse(type), ["validate"], {});
      return `Blended electricity rate must be a floating point value from ${min} to ${max}`;
    }
    case "blendedNaturalGasRate": {
      const { min, max } = _.get(calculations.parse(type), ["validate"], {});
      return `Blended natural gas rate must be a floating point value from ${min} to ${max}`;
    }
    case "realDiscountRate": {
      const { min, max } = _.get(calculations.parse(type), ["validate"], {});
      return `Real discount rate must be an integer value from ${min} to ${max}`;
    }
    case "rtuLifetime": {
      const { min, max } = _.get(calculations.parse(type), ["validate"], {});
      return `RTU lifetime must an integer value from ${min} to ${max}`;
    }
    case "numberOfSpeeds": {
      const { min, max } = _.get(calculations.parse(type), ["validate"], {});
      return `Number of speeds must be an integer value from ${min} to ${max}`;
    }
    case "eer": {
      const { min, max } = _.get(calculations.parse(type), ["validate"], {});
      return `EER must be a floating point value from ${min} to ${max}`;
    }
    case "heatingCop": {
      const { min, max } = _.get(calculations.parse(type), ["validate"], {});
      return `Heating COP must be a floating point value from ${min} to ${max}`;
    }
    case "minOaFraction": {
      const { min, max } = _.get(calculations.parse(type), ["validate"], {});
      return `Minimum OA fraction nust be a floating point value from ${min} to ${max}`;
    }
    case "maximumLimitDryBulb": {
      const { min, max } = _.get(calculations.parse(type), ["validate"], {});
      return `Maximum limit dry bulb must be a floating point value from ${min} to ${max}`;
    }
    case "capitalCost": {
      const { min, max } = _.get(calculations.parse(type), ["validate"], {});
      return `Capital cost must be an integer value from ${min} to ${max}`;
    }
    case "dayStart": {
      const { min, max } = _.get(schedules.parse("mondayStart"), ["validate"], {});
      return `Start of day must be an integer value from ${min} to ${max}`;
    }
    case "dayEnd": {
      const { min, max } = _.get(schedules.parse("mondayEnd"), ["validate"], {});
      return `End of day must be an integer value from ${min - 12} to ${max - 12}`;
    }
    default:
      return "";
  }
};

export const validate = (type) => {
  switch (type) {
    case "description":
    case "email":
    case "username":
    case "name":
    case "text":
    case "ssid":
    case "password":
    case "url":
    case "ip":
    case "coolingSetpoint":
    case "coolingSetback":
    case "heatingSetpoint":
    case "heatingSetback":
    case "oversizeFactor":
    case "coolingCapacity":
    case "blendedElectricityRate":
    case "blendedNaturalGasRate":
    case "realDiscountRate":
    case "rtuLifetime":
    case "numberOfSpeeds":
    case "eer":
    case "heatingCop":
    case "minOaFraction":
    case "maximumLimitDryBulb":
    case "capitalCost":
    case "dayStart":
    case "dayEnd":
      break;
    default:
      throw new Error(`Unknown validate option passed to form.validate(): ${type}`);
  }
  return {
    isValid: (value, values) => isValid(type, value, values),
    getMessage: (value, values) => getMessage(type, value, values),
  };
};
