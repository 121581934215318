import { Paper, withStyles } from "@material-ui/core";
import React, { Fragment } from "react";
import clsx from "clsx";
import { Analytics } from "utils/analytics";
import styles from "./styles";
import { MuiButton } from "components";

class NewView extends React.Component {
  componentDidMount() {
    Analytics.getInstance().event(`New View`, "Opened");
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Paper className={clsx(classes.content, classes.formGroup)}>
          <MuiButton
            className={classes.formEntry}
            key="new-rtucc"
            color="primary"
            onClick={() => this.props.createCalculation()}
            variant="primary"
            type="submit"
          >
            Start New Rooftop Unit Comparison Calculation
          </MuiButton>
        </Paper>
      </Fragment>
    );
  }
}

export default withStyles(styles)(NewView);
