import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";

const MuiLabel = (props) => {
  const { classes, header, required } = props;

  return (
    <Typography className={classes.header} variant="h6">
      {header}
      {required ? (
        <Fragment>
          {" "}
          <span className={classes.required}>*</span>
        </Fragment>
      ) : null}
    </Typography>
  );
};

MuiLabel.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  required: PropTypes.bool,
};

export default withStyles(styles)(MuiLabel);
