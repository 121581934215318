import React from "react";
import { Typography } from "@material-ui/core";
import _ from "lodash";
import clsx from "clsx";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";

const MuiSection = (props) => {
  const { children, classes, header, noDivider, bordered } = props;

  return (
    <div
      className={clsx(
        classes.section,
        noDivider ? undefined : classes.sectionDivider,
        bordered ? classes.sectionBorder : undefined
      )}
    >
      {!_.isEmpty(header) ? (
        <Typography className={classes.title} variant="button">
          {header}
        </Typography>
      ) : null}
      {children}
    </div>
  );
};

MuiSection.propTypes = {};

export default withStyles(styles)(MuiSection);
