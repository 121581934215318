import { Grid, Typography } from "@material-ui/core";
import { MuiButton, MuiDialog, MuiLoading } from "components";
import {
  fetchCalculations,
  fetchCalculationsPoll,
  removeCalculation,
  selectActive,
  selectCalculationsBusy,
  selectSortCalculations,
  selectSortCalculationsBusy,
  setActive,
  sortCalculations,
} from "controllers/calculations/action";

import { DeleteOutline as DeleteOutlineIcon } from "@material-ui/icons";
import HistoryTable from "./HistoryTable/HistoryTable";
import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";
import mixin from "utils/mixin";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";

class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: { field: "createdAt", direction: "desc" },
      search: "",
      selection: [],
      dialog: undefined,
      redirect: false,
      allowDelete: false,
    };
    _.merge(this, mixin);
  }

  componentDidMount() {
    const { search, sort } = this.state;
    this.props.sortCalculations({ ...sort, search: search });
    this.props.fetchCalculations();
    this.props.fetchCalculationsPoll(process.env.REACT_APP_POLLING);
  }

  componentWillUnmount() {
    this.props.fetchCalculationsPoll();
  }

  handleSearch = (search) => {
    const { sort } = this.state;
    this.setState({ search });
    this.props.sortCalculations({
      ...sort,
      search,
    });
  };

  handleSort = (sort) => {
    const { search } = this.state;
    this.setState({ sort });
    this.props.sortCalculations({
      ...sort,
      search,
    });
  };

  handleDelete = () => {
    const { selection, data } = this.state;
    selection.forEach((id) => {
      const uuid = _.get(_.find(data, { id }), ["uuid"]);
      if (!_.isEmpty(uuid)) {
        this.props.setActive();
      }
      this.props.removeCalculation({ id });
    });
    this.setState({ dialog: undefined, selection: [] });
  };

  handleClose = () => {
    this.setState({ dialog: undefined });
  };

  handleView = (item) => {
    const uuid = _.get(item, ["uuid"]);
    this.props.setActive(uuid);
    this.setState({ redirect: item });
  };

  renderDialog() {
    const { dialog, selection } = this.state;
    switch (dialog) {
      case "delete":
        return (
          <MuiDialog
            open={true}
            title="Confirmation"
            onClose={this.handleClose}
            buttons={[
              {
                disabled: false,
                label: "Cancel",
                onClick: this.handleClose,
                variant: "",
              },
              {
                disabled: false,
                label: "Delete",
                onClick: this.handleDelete,
                variant: "error",
                type: "submit",
              },
            ]}
          >
            <Typography>
              Do you want to delete {selection.length} item
              {selection.length === 1 ? "" : "s"}?
            </Typography>
          </MuiDialog>
        );
      default:
      // no need to handle all cases
    }
  }

  renderHeader() {
    const { classes } = this.props;
    const { selection } = this.state;
    return (
      <div className={classes.header}>
        <Typography className={classes.headerTitle}>History</Typography>
        <div className={classes.spacer} />
        <MuiButton
          className={classes.delete}
          disabled={selection.length === 0}
          onClick={() => this.handleChange("dialog")(null, "delete")}
          variant="primary"
          icon={DeleteOutlineIcon}
        >
          <DeleteOutlineIcon />
          Delete Selected
        </MuiButton>
      </div>
    );
  }

  render() {
    const { classes, data, loading, busy } = this.props;
    const { search, sort, selection, redirect, allowDelete } = this.state;
    if (redirect) {
      return <Redirect push to={`/calculate?uuid=${redirect.uuid}`} />;
    }
    return (
      <Fragment>
        <Grid
          className={classes.root}
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
          spacing={0}
        >
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              {allowDelete && this.renderHeader()}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              {(loading || busy) && !data ? (
                <MuiLoading />
              ) : (
                <HistoryTable
                  data={data}
                  busy={busy}
                  sort={sort}
                  handleSort={this.handleSort}
                  search={search}
                  handleSearch={this.handleSearch}
                  selection={selection}
                  handleSelection={(v) => this.handleChange("selection")(null, v)}
                  handleView={this.handleView}
                  allowDelete={allowDelete}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        {this.renderDialog()}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  active: selectActive(state),
  data: selectSortCalculations(state),
  loading: selectCalculationsBusy(state),
  busy: selectSortCalculationsBusy(state),
});

const mapActionToProps = {
  setActive,
  fetchCalculations,
  fetchCalculationsPoll,
  sortCalculations,
  removeCalculation,
};

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(History));
