import { FETCH_TEXT, fetchTextBusy, fetchTextError, fetchTextSuccess } from "./action";
import { call, put, takeLatest } from "redux-saga/effects";
import { readText } from "./api";

import { ActionTypes } from "../util";
import { logError } from "utils/utils";
import _ from "lodash";

const { REQUEST } = ActionTypes;

export const transformText = (text) => {
  if (!text) {
    return text;
  }
  return _.merge(
    text.reduce((p, c) => _.merge(p, { [c.key]: c.value }), {}),
    { metadata: text.filter((v) => v.metadata).reduce((p, c) => _.merge(p, { [c.key]: c.metadata }), {}) }
  );
};

export function* readTextSaga() {
  try {
    yield put(fetchTextBusy(true));
    yield put(fetchTextError());
    let response = yield call(readText);
    response = yield call(transformText, response);
    yield put(fetchTextSuccess(response));
  } catch (error) {
    logError(error);
    yield put(fetchTextError(error.message));
  } finally {
    yield put(fetchTextBusy(false));
  }
}

export default function* textsSaga() {
  yield takeLatest(FETCH_TEXT[REQUEST], readTextSaga);
}
