import { Paper, Typography } from "@material-ui/core";
import React from "react";
import mixin from "utils/mixin";
import _ from "lodash";
import clsx from "clsx";
import { buildCalculationLabel, buildRtucLabel, buildScheduleLabel } from "controllers/calculations/util";
import { Info as InfoIcon } from "@material-ui/icons";
import { Baseline, Upgraded } from "rtucc-common/constants";
import { Analytics } from "utils/analytics";
import stages from "rtucc-common/constants/stages";

class Overview extends React.Component {
  constructor(props) {
    super(props);
    _.merge(this, mixin);
  }

  componentDidMount() {
    const { stage } = this.props;
    if (stage) {
      Analytics.getInstance().event(`Overview ${_.get(stages.parse(stage), "label", "Unknown")}`, "Opened");
    }
  }

  renderDescription() {
    const { classes, stage } = this.props;
    switch (stage) {
      case "queue":
        return (
          <Typography className={classes.text} variant={"h6"}>
            This calculation is currently waiting in the queue and will be processed in the order that it was submitted.
          </Typography>
        );
      case "process":
        return (
          <Typography className={classes.text} variant={"h6"}>
            This calculation is currently being processed and will display the results once completed.
          </Typography>
        );
      default:
        return null;
    }
  }

  renderSection(id, label) {
    const { classes } = this.props;
    return <Typography className={classes.heading}>{label}</Typography>;
  }

  render() {
    const { classes, calculation } = this.props;
    return (
      <React.Fragment>
        <div className={classes.center}>
          <Paper className={clsx(classes.description)}>
            <InfoIcon className={classes.infoIcon} />
            {this.renderDescription()}
          </Paper>
        </div>
        <div className={classes.padding} />
        <Paper className={clsx(classes.content)}>
          {this.renderSection("calculation", buildCalculationLabel(calculation))}
          {this.renderSection("schedule", buildScheduleLabel(calculation.Schedule))}
          {this.renderSection("baseline", buildRtucLabel(calculation, Baseline))}
          {this.renderSection("upgraded", buildRtucLabel(calculation, Upgraded))}
        </Paper>
      </React.Fragment>
    );
  }
}

export default Overview;
