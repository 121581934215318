import GoogleAnalytics from "react-ga";
import { install, gtag } from "ga-gtag";
import { isEmpty } from "lodash";

const LOG = "log";
const GA = "ga";
const GT = "gt";

export class Analytics {
  static instance;

  /**
   * Singleton getter method.
   *
   * @returns the single instance
   */
  static getInstance() {
    if (!Analytics.instance) {
      Analytics.instance = new Analytics();
    }
    return Analytics.instance;
  }

  _initialized;

  constructor() {
    this._initialized = [];
    this.initialize();
  }

  initialize = () => {
    if (isEmpty(this._initialized)) {
      if (!isEmpty(process.env.REACT_APP_GA_CLIENT_ID)) {
        GoogleAnalytics.initialize(process.env.REACT_APP_GA_CLIENT_ID);
        this._initialized.push(GA);
      }
      if (!isEmpty(process.env.REACT_APP_GA_MEASUREMENT_ID)) {
        install(process.env.REACT_APP_GA_MEASUREMENT_ID);
        this._initialized.push(GT);
      }
      if (isEmpty(this._initialized)) {
        this._initialized.push(LOG);
      }
    }
  };

  /**
   * Called for a page view.
   *
   * @param path
   * @param query
   */
  pageview = (path, query) => {
    if (this._initialized.includes(GA)) {
      GoogleAnalytics.pageview(`${path}${query ? query : ""}`);
    }
    if (this._initialized.includes(GT)) {
      gtag("event", "View", { path, query });
    }
    if (this._initialized.includes(LOG)) {
      console.log(`Analytics Debug Page View: ${path}${query ? query : ""}`);
    }
  };

  /**
   * Called for explicit user actions.
   *
   * @param category
   * @param action
   * @param label
   */
  event = (category, action, label) => {
    if (this._initialized.includes(GA)) {
      GoogleAnalytics.event({ category, action, label });
    }
    if (this._initialized.includes(GT)) {
      gtag("event", category, { category, action, label });
    }
    if (this._initialized.includes(LOG)) {
      console.log(`Analytics Debug Event: ${category} -> ${action}${label ? " (" + label + ")" : ""}`);
    }
  };
}
