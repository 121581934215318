import { generateActions, generateSelectors, generateTypes } from "../util";

export const key = "text";

// fetch text
export const FETCH_TEXT = generateTypes(key, "current");
export const [
  fetchText,
  fetchTextSuccess,
  fetchTextError,
  fetchTextBusy,
  fetchTextPoll,
] = generateActions(FETCH_TEXT);
export const [
  selectText,
  selectTextError,
  selectTextBusy,
  selectTextPoll,
  selectTextRequest,
] = generateSelectors(FETCH_TEXT);
