import { MuiButton, MuiCheckbox, MuiSearchField } from "components";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";

import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import { createTerm } from "utils/utils";
import mixin from "utils/mixin";
import moment from "moment";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";

moment.locale();

class HistoryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { rows: 10, page: 0 };
    _.merge(this, mixin);
  }

  handleUpdate = (key, state) => () => {
    switch (key) {
      case "rows":
        this.setState({
          page: 0,
        });
        break;
      default:
    }
  };

  getPage = () => {
    const { data } = this.props;
    const { rows, page } = this.state;
    const length = data ? data.length : 0;
    return page * rows < length ? page : 0;
  };

  handleSort = (key) => () => {
    const { sort } = this.props;
    this.setState({ page: 0 });
    this.props.handleSort({
      field: key,
      direction: sort && sort.field === key && sort.direction === "asc" ? "desc" : "asc",
    });
  };

  handleSearch = (event, value) => {
    this.setState({ page: 0 });
    this.props.handleSearch(_.has(event, ["target", "value"]) ? event.target.value : value);
  };

  handleCheck = (id) => (event, value) => {
    const { selection } = this.props;
    const checked = _.has(event, ["target", "value"]) ? event.target.value : value;
    this.props.handleSelection(checked ? _.union([id], selection) : _.difference(selection, [id]));
  };

  isChecked = () => {
    const { data, selection } = this.props;
    const ids = data ? data.map((v) => v.id) : [];
    return selection.length > 0 && selection.length === ids.length;
  };

  isIndeterminate = () => {
    const { data, selection } = this.props;
    const ids = data ? data.map((v) => v.id) : [];
    return selection.length > 0 && selection.length < ids.length;
  };

  handleSelect = () => {
    if (this.isChecked()) {
      this.props.handleSelection([]);
    } else {
      const { data } = this.props;
      const ids = data ? data.map((v) => v.id) : [];
      this.props.handleSelection(ids);
    }
  };

  renderHeader() {
    const { classes, sort, allowDelete } = this.props;
    return (
      <TableHead>
        <TableRow className={classes.header}>
          {allowDelete && (
            <TableCell className={classes.first}>
              <MuiCheckbox
                className={classes.headerLabel}
                checked={this.isChecked()}
                indeterminate={this.isIndeterminate()}
                onChange={this.handleSelect}
              />
            </TableCell>
          )}
          <TableCell>
            <TableSortLabel
              className={classes.sort}
              active={sort && sort.field === "createdAt"}
              direction={sort && sort.field === "createdAt" ? sort.direction : "asc"}
              onClick={this.handleSort("createdAt")}
              IconComponent={ArrowDropDownIcon}
            >
              <Typography className={classes.headerLabel} variant="h6">
                Date &amp; Time Created
              </Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              className={classes.sort}
              active={sort && sort.field === "updatedAt"}
              direction={sort && sort.field === "updatedAt" ? sort.direction : "asc"}
              onClick={this.handleSort("updatedAt")}
              IconComponent={ArrowDropDownIcon}
            >
              <Typography className={classes.headerLabel} variant="h6">
                Date &amp; Time Updated
              </Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              className={classes.sort}
              active={sort && sort.field === "label"}
              direction={sort && sort.field === "label" ? sort.direction : "asc"}
              onClick={this.handleSort("label")}
              IconComponent={ArrowDropDownIcon}
            >
              <Typography className={classes.headerLabel} variant="h6">
                Label
              </Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              className={classes.sort}
              active={sort && sort.field === "uuid"}
              direction={sort && sort.field === "uuid" ? sort.direction : "asc"}
              onClick={this.handleSort("uuid")}
              IconComponent={ArrowDropDownIcon}
            >
              <Typography className={classes.headerLabel} variant="h6">
                UUID
              </Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              className={classes.sort}
              active={sort && sort.field === "stage"}
              direction={sort && sort.field === "stage" ? sort.direction : "asc"}
              onClick={this.handleSort("stage")}
              IconComponent={ArrowDropDownIcon}
            >
              <Typography className={classes.headerLabel} variant="h6">
                Stage
              </Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <Typography className={classes.headerLabel} variant="h6">
              Calculate
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  renderFooter() {
    const { data, search, busy } = this.props;
    const { rows } = this.state;
    const page = this.getPage();
    return (
      <TableFooter>
        <TableRow>
          <TableCell colSpan={3}>
            <MuiSearchField
              id="search"
              value={search}
              onChange={this.handleSearch}
              placeholder="Filter Calculations"
              busy={Boolean(busy)}
            />
          </TableCell>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 1000]}
            colSpan={3}
            count={data ? data.length : 0}
            rowsPerPage={rows}
            page={page}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true,
            }}
            onPageChange={this.handleChange("page")}
            onRowsPerPageChange={(e) => this.handleChange("rows")(null, parseInt(e.target.value, 10))}
          />
        </TableRow>
      </TableFooter>
    );
  }

  renderTableBody() {
    const { classes, data, selection, allowDelete } = this.props;
    const { rows } = this.state;
    const page = this.getPage();
    return (
      <TableBody className={classes.body}>
        {data &&
          data.slice(page * rows, page * rows + rows).map((item) => (
            <TableRow className={classes.row} key={`row-${item.id}`} hover>
              {allowDelete && (
                <TableCell className={classes.first}>
                  <MuiCheckbox checked={selection.includes(item.id)} onChange={this.handleCheck(item.id)} />
                </TableCell>
              )}
              <TableCell>{moment(item.createdAt).format("MM/DD/YYYY HH:mm:ss")}</TableCell>
              <TableCell>{moment(item.updatedAt).format("MM/DD/YYYY HH:mm:ss")}</TableCell>
              <TableCell>{createTerm(item, "label")}</TableCell>
              <TableCell>{createTerm(item, "uuid")}</TableCell>
              <TableCell>{createTerm(item, "stage")}</TableCell>
              <TableCell>
                <div className={classes.flex}>
                  <MuiButton color="primary" onClick={() => this.props.handleView(item)}>
                    <Typography variant="button">
                      <strong>View</strong>
                    </Typography>
                  </MuiButton>
                  <div className={classes.spacer} />
                </div>
              </TableCell>
            </TableRow>
          ))}
        <TableRow>
          <TableCell colSpan={5} />
        </TableRow>
      </TableBody>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Table className={classes.table} padding="none" size="small">
          {this.renderHeader()}
          {this.renderTableBody()}
          {this.renderFooter()}
        </Table>
      </div>
    );
  }
}

HistoryTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  sort: PropTypes.shape({
    field: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
  }),
  handleSort: PropTypes.func.isRequired,
};

export default withStyles(styles)(HistoryTable);
