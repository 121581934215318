import { Grid, SnackbarContent, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./styles";

class Home extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.root} container alignContent="center" justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" paragraph>
            The Rooftop Unit <Link to="/calculate">Comparison Calculator</Link>
            (RTUCC) compares high-efficiency rooftop air conditioners to standard equipment in terms of life cycle cost.
            This web application provides an alternative to complicated building simulation models while offering more
            detail than simplified estimating tools that are commonly available. While simplified tools are typically
            based on full-load efficiencies and full-load equivalent operating hours, the RTUCC accounts for local
            climate and partial-load as well as full-load efficiencies.
          </Typography>
          <Typography variant="body1" paragraph>
            The Comparison Calculator assists decision-making regarding the purchase or replacement of packaged rooftop
            air conditioning equipment by estimating a product's lifetime energy cost savings at various efficiency
            levels. Other key features include the following:
          </Typography>
          <ul>
            <li>The calculator is fully web-based.</li>
            <li>Menu-driven format is easy to learn and use. </li>
            <li>
              Quickly estimates life cycle cost, simple payback, return on investment, and savings-to-investment ratio.
            </li>
            <li>
              Simulates operation under specific climate conditions at any of 237 U.S. locations selected by the user.
            </li>
            <li>Reflects user-specified air-conditioning requirements and building-use patterns. </li>
            <li>Results are easily downloaded as graphic files and tables for further analysis and presentation. </li>
          </ul>
          <Typography variant="body1" paragraph>
            The results obtained with the Comparison Calculator will be useful to facility managers, financial officers,
            energy management professionals, air conditioning contractors, and other decision makers interested in
            maximizing both energy-efficiency and cost-effectiveness of cooling system investments. The calculator
            provides energy and cost estimates suitable for comparison purposes. The RTUCC was not designed to replace
            building load simulating software for heating and cooling systems design. Due to the dynamic nature of
            building heating and cooling, actual energy use will vary depending on the application.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>
              The Comparison Calculator is a screening tool that estimates a product's lifetime energy cost savings at
              various efficiency levels. It provides energy and cost estimates suitable for comparison purposes only.
              The Comparison Calculator is not designed to replace building load simulating software for heating and
              cooling systems design. Actual performance and costs will vary depending on specific operating conditions.
              Neither the U.S.&nbsp;Department of Energy nor Pacific Northwest National Laboratory is responsible for
              any adverse outcome associated with results generated by the Comparison Calculator.
            </strong>
          </Typography>
          <div className={clsx(classes.center, classes.pad)}>
            <SnackbarContent
              className={clsx(classes.error, classes.snackbar)}
              aria-describedby="client-snackbar"
              message={
                <span id="client-snackbar" className={classes.message}>
                  {/* <Icon className={clsx(classes.icon, classes.iconVariant)} /> */}
                  <p>
                    Pacific Northwest National Laboratory is planning to host a webinar to introduce the update RTUCC
                    tool to the users. In the meantime, please refer to the RTUCC Instructions PowerPoint file for a
                    short tutorial on the use of the updated tool.{" "}
                    <span>
                      <a href="/docs/RTUCC_Instructions.pptx">RTUCC&nbsp;Instructions.pptx</a>
                    </span>
                  </p>
                </span>
              }
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapActionToProps = {};

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(Home));
