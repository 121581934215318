import aboutRoute from "./About/route";
import calculationRoute from "./Calculation/route";
import contentRoute from "./Content/route";
import errorRoute from "./Error/route";
import homeRoute from "./Home/route";
import linksRoute from "./Links/route";
import historyRoute from "./History/route";
import notfoundRoute from "./NotFound/route";

export { default as About } from "./About";
export { default as Calculation } from "./Calculation";
export { default as Content } from "./Content";
export { default as Error } from "./Error";
export { default as Home } from "./Home";
export { default as Links } from "./Links";
export { default as History } from "./History";
export { default as NotFound } from "./NotFound";

export const routes = [
  aboutRoute,
  calculationRoute,
  contentRoute,
  errorRoute,
  homeRoute,
  linksRoute,
  historyRoute,
  notfoundRoute,
]
  .sort((a, b) => a.sequence - b.sequence)
  .map((route, index) => ({
    ...route,
    index,
  }));
