const styles = {
  error: {
    fontSize: ".8rem",
  },
  tabs: {
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingBottom: "12px",
  },
};

export default styles;
