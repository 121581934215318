import _ from "lodash";
import {
  days,
  compressors,
  economizers,
  fans,
  heatings,
  buildings,
  windows,
  orientations,
  climates,
  zones,
  calculations,
  Upgraded,
  Baseline,
} from "rtucc-common/constants";
import stages from "constants/stage";
import { validate } from "utils/form";

const dayLabels = days.values().map((day) => day.name);

const transformClientValues = (values) =>
  _.merge(
    _.pick(values, [
      "id",
      "uuid",
      "stage",
      "label",
      "building",
      "zone",
      "window",
      "orientation",
      "climate",
      "coolingSetpoint",
      "coolingSetback",
      "heatingSetpoint",
      "heatingSetback",
      "oversizeFactor",
      "coolingCapacity",
      "blendedElectricityRate",
      "blendedNaturalGasRate",
      "realDiscountRate",
      "rtuLifetime",
      "compressorBaseline",
      "numberOfSpeedsBaseline",
      "fanBaseline",
      "eerBaseline",
      "heatingBaseline",
      "heatingCopBaseline",
      "minOaFractionBaseline",
      "economizerBaseline",
      "maximumLimitDryBulbBaseline",
      "capitalCostBaseline",
      "compressorUpgraded",
      "numberOfSpeedsUpgraded",
      "fanUpgraded",
      "eerUpgraded",
      "heatingUpgraded",
      "heatingCopUpgraded",
      "minOaFractionUpgraded",
      "economizerUpgraded",
      "maximumLimitDryBulbUpgraded",
      "capitalCostUpgraded",
    ]),
    {
      Schedule: _.pick(values, [
        "scheduleId",
        "mondayStart",
        "mondayEnd",
        "tuesdayStart",
        "tuesdayEnd",
        "wednesdayStart",
        "wednesdayEnd",
        "thursdayStart",
        "thursdayEnd",
        "fridayStart",
        "fridayEnd",
        "saturdayStart",
        "saturdayEnd",
        "sundayStart",
        "sundayEnd",
      ]),
    }
  );

export const defaultClientValues = () => ({
  id: "",
  uuid: "",
  stage: stages.values().pop().label,
  label: "",
  building: buildings.values().pop().label,
  zone: buildings
    .values()
    .pop()
    .zones.pop().label,
  window: windows.values().pop().label,
  orientation: buildings
    .values()
    .pop()
    .orientations.pop().label,
  climate: climates
    .values()
    .filter((v) => v.valid)
    .pop().label,
  coolingSetpoint: "",
  coolingSetback: "",
  heatingSetpoint: "",
  heatingSetback: "",
  oversizeFactor: "",
  coolingCapacity: "",
  blendedElectricityRate: "",
  blendedNaturalGasRate: "",
  realDiscountRate: "",
  rtuLifetime: "",
  compressorBaseline: compressors.values().pop().label,
  numberOfSpeedsBaseline: "",
  fanBaseline: fans.values().pop().label,
  eerBaseline: "",
  heatingBaseline: "",
  heatingCopBaseline: "",
  minOaFractionBaseline: "",
  economizerBaseline: economizers.values().pop().label,
  maximumLimitDryBulbBaseline: "",
  capitalCostBaseline: "",
  compressorUpgraded: compressors.values().pop().label,
  numberOfSpeedsUpgraded: "",
  fanUpgraded: fans.values().pop().label,
  eerUpgraded: "",
  heatingUpgraded: "",
  heatingCopUpgraded: "",
  minOaFractionUpgraded: "",
  economizerUpgraded: economizers.values().pop().label,
  maximumLimitDryBulbUpgraded: "",
  capitalCostUpgraded: "",
  scheduleId: "",
  mondayStart: "",
  mondayEnd: "",
  tuesdayStart: "",
  tuesdayEnd: "",
  wednesdayStart: "",
  wednesdayEnd: "",
  thursdayStart: "",
  thursdayEnd: "",
  fridayStart: "",
  fridayEnd: "",
  saturdayStart: "",
  saturdayEnd: "",
  sundayStart: "",
  sundayEnd: "",
});

export const toServerValues = (values) => {
  const temp = transformClientValues(values);
  temp.Schedule.id = parseInt(temp.Schedule.scheduleId);
  delete temp.Schedule.scheduleId;
  dayLabels.forEach((day) => (temp.Schedule[`${day}End`] = parseInt(temp.Schedule[`${day}End`]) + 12));
  Object.keys(temp)
    .filter((key) => !isNaN(temp[key]) && !isNaN(parseFloat(temp[key])))
    .forEach((key) => (temp[key] = parseFloat(temp[key])));
  temp.stage = stages.parse(temp.stage).name;
  temp.building = buildings.parse(temp.building).name;
  temp.zone = zones.parse(temp.zone).name;
  temp.window = windows.parse(temp.window).name;
  temp.orientation = orientations.parse(temp.orientation).name;
  temp.climate = climates.parse(temp.climate).name;
  temp.compressorBaseline = compressors.parse(temp.compressorBaseline).name;
  temp.fanBaseline = fans.parse(temp.fanBaseline).name;
  temp.heatingBaseline = heatings.parse(temp.heatingBaseline).name;
  temp.economizerBaseline = economizers.parse(temp.economizerBaseline).name;
  temp.compressorUpgraded = compressors.parse(temp.compressorUpgraded).name;
  temp.fanUpgraded = fans.parse(temp.fanUpgraded).name;
  temp.heatingUpgraded = heatings.parse(temp.heatingUpgraded).name;
  temp.economizerUpgraded = economizers.parse(temp.economizerUpgraded).name;
  return temp;
};

export const toClientValues = (values) => {
  const temp = _.merge({}, _.get(values, ["Schedule"]), _.omit(values, ["Schedule"]));
  temp.scheduleId = `${_.get(values, ["Schedule", "id"])}`;
  dayLabels.forEach((day) => (temp[`${day}End`] -= 12));
  Object.keys(temp).forEach((key) => (temp[key] = `${temp[key]}`));
  temp.stage = stages.parse(temp.stage).label;
  temp.building = buildings.parse(temp.building).label;
  temp.zone = zones.parse(temp.zone).label;
  temp.window = windows.parse(temp.window).label;
  temp.orientation = orientations.parse(temp.orientation).label;
  temp.climate = climates.parse(temp.climate).label;
  temp.compressorBaseline = compressors.parse(temp.compressorBaseline).label;
  temp.fanBaseline = fans.parse(temp.fanBaseline).label;
  temp.heatingBaseline = heatings.parse(temp.heatingBaseline).label;
  temp.economizerBaseline = economizers.parse(temp.economizerBaseline).label;
  temp.compressorUpgraded = compressors.parse(temp.compressorUpgraded).label;
  temp.fanUpgraded = fans.parse(temp.fanUpgraded).label;
  temp.heatingUpgraded = heatings.parse(temp.heatingUpgraded).label;
  temp.economizerUpgraded = economizers.parse(temp.economizerUpgraded).label;
  return temp;
};

export const disabledFields = (values) => {
  const disabled = [];
  Object.keys(values).forEach((key) => {
    let suffix = Upgraded;
    switch (key) {
      case "economizerBaseline":
        suffix = Baseline;
      // fall through
      case "economizerUpgraded": {
        const economizer = economizers.parse(values[key]);
        _.get(economizer, ["disabled"], []).forEach((k) => {
          disabled.push(`${k}${suffix}`);
        });
        break;
      }
      case "heatingBaseline":
        suffix = Baseline;
      // fall through
      case "heatingUpgraded": {
        const heating = heatings.parse(values[key]);
        _.get(heating, ["disabled"], []).forEach((k) => {
          disabled.push(`${k}${suffix}`);
        });
        break;
      }
      case "compressorBaseline":
        suffix = Baseline;
      // fall through
      case "compressorUpgraded": {
        const compressor = compressors.parse(values[key]);
        _.get(compressor, ["disabled"], []).forEach((k) => {
          disabled.push(`${k}${suffix}`);
        });
        break;
      }
      default:
    }
  });
  return disabled;
};

export const errorMessages = (values, key, setState) => {
  const errors = {};
  const update = {};
  let message;
  let suffix = Upgraded;
  switch (key) {
    case "zone":
    case "orientation": {
      const { zone, orientation } = values;
      try {
        _.merge({}, values, calculations.parse("zone").validate).isValidForBuilding(zone);
        _.merge({}, values, calculations.parse("orientation").validate).isValidForBuilding(orientation);
      } catch (error) {
        message = error.message;
      }
      break;
    }
    case "building": {
      let { building, zone, orientation } = values;
      building = buildings.parse(building);
      try {
        _.merge({}, values, calculations.parse("zone").validate).isValidForBuilding(zone);
      } catch (error) {
        update.zone = building.zones.pop().label;
      }
      errors.zone = undefined;
      try {
        _.merge({}, values, calculations.parse("orientation").validate).isValidForBuilding(orientation);
      } catch (error) {
        update.orientation = building.orientations.pop().label;
      }
      errors.orientation = undefined;
      break;
    }
    case "coolingSetpoint":
    case "coolingSetback":
    case "heatingSetpoint":
    case "heatingSetback":
    case "oversizeFactor":
    case "coolingCapacity":
    case "blendedElectricityRate":
    case "blendedNaturalGasRate":
    case "realDiscountRate":
    case "rtuLifetime": {
      message = validate(key).getMessage(values[key]);
      if (!message) {
        const { heatingSetpoint, coolingSetpoint } = values;
        if (heatingSetpoint >= coolingSetpoint) {
          message = "Heating setpoint must be less than cooling setpoint.";
        }
      }
      break;
    }
    case "mondayStart":
    case "tuesdayStart":
    case "wednesdayStart":
    case "thursdayStart":
    case "fridayStart":
    case "saturdayStart":
    case "sundayStart": {
      message = validate("dayStart").getMessage(values[key]);
      break;
    }
    case "mondayEnd":
    case "tuesdayEnd":
    case "wednesdayEnd":
    case "thursdayEnd":
    case "fridayEnd":
    case "saturdayEnd":
    case "sundayEnd": {
      message = validate("dayEnd").getMessage(values[key]);
      break;
    }
    case "numberOfSpeedsBaseline":
    case "numberOfSpeedsUpgraded": {
      message = validate("numberOfSpeeds").getMessage(values[key]);
      break;
    }
    case "eerBaseline":
    case "eerUpgraded": {
      message = validate("eer").getMessage(values[key]);
      break;
    }
    case "heatingCopBaseline":
    case "heatingCopUpgraded": {
      message = validate("heatingCop").getMessage(values[key]);
      break;
    }
    case "minOaFractionBaseline":
    case "minOaFractionUpgraded": {
      message = validate("minOaFraction").getMessage(values[key]);
      break;
    }
    case "maximumLimitDryBulbBaseline":
    case "maximumLimitDryBulbUpgraded": {
      message = validate("maximumLimitDryBulb").getMessage(values[key]);
      break;
    }
    case "capitalCostBaseline":
    case "capitalCostUpgraded": {
      message = validate("capitalCost").getMessage(values[key]);
      break;
    }
    case "economizerBaseline":
      suffix = Baseline;
    // fall through
    case "economizerUpgraded": {
      const economizer = economizers.parse(values[key]);
      _.get(economizer, ["disabled"], []).forEach((k) => {
        const model = calculations.parse(k);
        update[`${k}${suffix}`] = `${model.defaultValue}`;
        errors[`${k}${suffix}`] = undefined;
      });
      break;
    }
    case "heatingBaseline":
      suffix = Baseline;
    // fall through
    case "heatingUpgraded": {
      const heating = heatings.parse(values[key]);
      _.get(heating, ["disabled"], []).forEach((k) => {
        const model = calculations.parse(k);
        update[`${k}${suffix}`] = `${model.defaultValue}`;
        errors[`${k}${suffix}`] = undefined;
      });
      break;
    }
    default: {
      message = undefined;
    }
  }
  errors[key] = message;
  if (Object.keys(update).length > 0) {
    setState(update);
  }
  return errors;
};
