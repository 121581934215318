import { withStyles } from "@material-ui/core/styles";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import mixin from "utils/mixin";
import styles from "./styles";
import _ from "lodash";
import { MuiLoading } from "components";
import queryString from "query-string";
import {
  createCalculation,
  fetchCalculation,
  updateCalculation,
  selectCalculation,
  fetchCalculationPoll,
  selectActive,
  setActive,
} from "controllers/calculations/action";
import InputView from "./InputView/InputView";
import HeaderView from "./HeaderView/HeaderView";
import ResultView from "./ResultView/ResultView";
import Overview from "./Overview";
import stages from "constants/stage";
import { fetchText, selectText } from "controllers/text/action";
import { parseBoolean } from "utils/utils";
import NewView from "./NewView";
import ErrorView from "./ErrorView";

class Calculation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: true,
      uuid: "",
    };
    _.merge(this, mixin);
  }

  componentDidMount() {
    const { location, active } = this.props;
    const query = queryString.parse(location.search);
    const uuid = !_.isEmpty(query.uuid) ? query.uuid : active;
    if (!_.isEmpty(query.uuid) && query.uuid !== active) {
      this.props.setActive(query.uuid);
    }
    if (!_.isEmpty(uuid)) {
      this.props.fetchCalculation({ uuid: uuid });
      this.props.fetchCalculationPoll(parseInt(process.env.REACT_APP_POLLING));
    }
    this.props.fetchText();
  }

  componentDidUpdate() {
    const { active, history } = this.props;
    const { uuid: current } = this.state;
    if (!_.isEmpty(active) && active !== current) {
      this.setState({ uuid: active });
      const search = `?uuid=${active}`;
      if (history.location.search !== search) {
        history.push({
          search,
        });
      }
      this.props.fetchCalculation({ uuid: active });
      this.props.fetchCalculationPoll(parseInt(process.env.REACT_APP_POLLING));
    }
  }

  componentWillUnmount() {
    this.props.fetchCalculationPoll();
  }

  clearCalculation() {
    const { history } = this.props;
    this.setState(
      {
        valid: true,
        uuid: "",
      },
      () => {
        this.props.setActive();
        history.push({
          search: "",
        });
        this.props.fetchCalculation();
      }
    );
  }

  handleStepChange = (index) => {
    const id = _.get(this.props, ["calculation", "id"], "-1");
    const previous = _.get(stages.parse(this.getStage()), ["index"], 0);
    const stage = _.get(stages.parse(index), ["name"], "reprocess");
    if (previous - index === 1) {
      // one step down should clear the calculation
      this.clearCalculation();
      return;
    }
    switch (stage) {
      case "create":
        this.props.createCalculation();
        break;
      case "queue":
        this.props.updateCalculation({ id: parseInt(id), stage });
        break;
      case "process":
      case "new":
        this.clearCalculation();
        break;
      case "fail":
      case "complete":
      case "reprocess":
        if (parseBoolean(process.env.REACT_APP_ALLOW_REPROCESS)) {
          this.props.updateCalculation({ id: parseInt(id), stage: "create" });
        } else {
          this.clearCalculation();
        }
        break;
      default:
        throw new Error(`Unhandled enumeration: ${stage}`);
    }
  };

  handleValid = (valid) => {
    this.setState({ valid });
  };

  getStage() {
    const { uuid } = this.state;
    const stage =
      uuid === _.get(this.props, ["calculation", "uuid"], "")
        ? _.get(stages.parse(_.get(this.props, ["calculation", "stage"], "")), ["name"], "new")
        : "new";
    return stage;
  }

  createStepLabel(label) {
    if (label) {
      return <span style={{ width: "7em" }}>{label}</span>;
    } else {
      return <span style={{ width: "7em" }}>&nbsp;</span>;
    }
  }

  getStepLabels(stage) {
    const labels = {
      backLabel: this.createStepLabel(),
      nextLabel: this.createStepLabel(),
    };
    switch (stage) {
      case "create":
        labels.nextLabel = this.createStepLabel("Process");
      // fall through
      case "queue":
      case "process":
        labels.backLabel = this.createStepLabel("Clear");
        break;
      case "fail":
      case "complete":
        if (parseBoolean(process.env.REACT_APP_ALLOW_REPROCESS)) {
          labels.nextLabel = this.createStepLabel("Reprocess");
        }
        labels.backLabel = this.createStepLabel("Clear");
        break;
      case "new":
        labels.nextLabel = this.createStepLabel("New");
        break;
      default:
        throw new Error(`Unhandled enumeration: ${stage}`);
    }
    return labels;
  }

  renderView(stage) {
    const id = _.get(this.props, ["calculation", "id"], "none");
    switch (stage) {
      case "new":
        return <NewView id={`new-view-${id}`} {...this.props} />;
      case "create":
        return <InputView id={`input-view-${id}`} {...this.props} onValid={this.handleValid} />;
      case "queue":
        return <Overview id={`overview-${stage}-${id}`} stage={stage} {...this.props} />;
      case "process":
        return <Overview id={`overview-${stage}-${id}`} stage={stage} {...this.props} />;
      case "complete":
        return <ResultView id={`result-view-${id}`} stage={stage} {...this.props} />;
      case "fail":
        return <ErrorView id={`error-view-${id}`} {...this.props} />;
      default:
        return <MuiLoading />;
    }
  }

  render() {
    const { classes } = this.props;
    const { uuid, valid } = this.state;
    const stage = this.getStage();
    const { backLabel, nextLabel } = this.getStepLabels(stage);
    return (
      <div className={classes.root}>
        {_.isEmpty(uuid) && stage !== "new" ? (
          <MuiLoading />
        ) : (
          <Fragment>
            <HeaderView
              {...this.props}
              valid={valid}
              stage={stage}
              onStepChange={this.handleStepChange}
              backLabel={backLabel}
              nextLabel={nextLabel}
            />
            {this.renderView(stage)}
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  active: selectActive(state),
  calculation: selectCalculation(state),
  text: selectText(state),
});

const mapActionToProps = {
  setActive,
  createCalculation,
  fetchCalculation,
  fetchCalculationPoll,
  updateCalculation,
  fetchText,
};

export default connect(mapStateToProps, mapActionToProps)(withRouter(withStyles(styles)(Calculation)));
