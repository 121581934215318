import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import styles from "./styles";

class Links extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.root} container alignContent="center" justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <h3>HVAC Organizations</h3>
          <ul>
            <li>
              <a href="http://www.ahrinet.org" target="_blank" rel="noopener noreferrer">
                Air Conditioning, Heating, and Refrigeration Institute
              </a>{" "}
              (AHRI)
            </li>
            <li>
              <a href="http://www.ashrae.org" target="_blank" rel="noopener noreferrer">
                American Society of Heating, Refrigerating and Air Conditioning Engineers
              </a>
              (ASHRAE)
            </li>
            <li>
              <a href="http://www.smacna.org" target="_blank" rel="noopener noreferrer">
                Sheet Metal and Air Conditioning Contractors National Association
              </a>
              (SMACNA)
            </li>
            <li>
              <a href="http://www.acca.org" target="_blank" rel="noopener noreferrer">
                Air Conditioning Contractors of America
              </a>{" "}
              (ACCA)
            </li>
          </ul>

          <h3>Standards Organizations</h3>
          <ul>
            <li>
              <a href="http://www.ansi.org" target="_blank" rel="noopener noreferrer">
                American National Standards Institute
              </a>{" "}
              (ANSI)
            </li>
            <li>
              <a href="http://www.astm.org" target="_blank" rel="noopener noreferrer">
                American Society for Testing and Materials
              </a>{" "}
              (ASTM)
            </li>
            <li>
              <a href="http://www.etl.com" target="_blank" rel="noopener noreferrer">
                Electrical Testing Laboratories
              </a>{" "}
              (ETL)
            </li>
            <li>
              <a href="http://www.iso.ch" target="_blank" rel="noopener noreferrer">
                International Organization for Standardization
              </a>{" "}
              (ISO)
            </li>
            <li>
              <a href="http://www.nema.org" target="_blank" rel="noopener noreferrer">
                National Electric Manufacturers Association
              </a>{" "}
              (NEMA)
            </li>
            <li>
              <a href="http://www.nfpa.org" target="_blank" rel="noopener noreferrer">
                National Fire Protection Association
              </a>{" "}
              (NFPA)
            </li>
          </ul>

          <h3>Codes Organizations</h3>
          <ul>
            <li>
              <a href="http://www.intlcode.org" target="_blank" rel="noopener noreferrer">
                International Code Council
              </a>{" "}
              (ICC)
            </li>
            <li>
              <a href="http://www.nfpa.org" target="_blank" rel="noopener noreferrer">
                National Fire Protection Association
              </a>{" "}
              (NFPA)
            </li>
          </ul>

          <h3>US Government</h3>
          <ul>
            <li>
              <a href="http://www.energy.gov" target="_blank" rel="noopener noreferrer">
                US Department of Energy
              </a>
            </li>
            <li>
              <a href="http://www.eere.energy.gov/buildings/" target="_blank" rel="noopener noreferrer">
                Office of Building Technology, State and Community Programs
              </a>
              (BTS)
            </li>
            <li>
              <a href="http://www.eere.energy.gov/femp" target="_blank" rel="noopener noreferrer">
                Federal Energy Management Program
              </a>{" "}
              (FEMP)
            </li>
            <li>
              <a href="http://www.dla.mil" target="_blank" rel="noopener noreferrer">
                Defense Logistics Agency
              </a>{" "}
              (DLA)
            </li>
            <li>
              <a href="http://www.epa.gov" target="_blank" rel="noopener noreferrer">
                US Environmental Protection Agency
              </a>
            </li>
            <li>
              <a href="http://www.energystar.gov" target="_blank" rel="noopener noreferrer">
                Energy Star<sup>&reg;</sup> Program
              </a>
            </li>
            <li>
              <a href="https://www.pnnl.gov" target="_blank" rel="noopener noreferrer">
                Pacific Northwest National Laboratory
              </a>{" "}
              (PNNL)
            </li>
          </ul>

          <h3>State and Local Government</h3>
          <ul>
            <li>
              <a href="http://www.energy.ca.gov" target="_blank" rel="noopener noreferrer">
                California Energy Commission
              </a>{" "}
              (CEC)
            </li>
            <li>
              <a href="http://www.naseo.org" target="_blank" rel="noopener noreferrer">
                National Association of State Energy Officials
              </a>{" "}
              (NASEO)
            </li>
          </ul>

          <h3>Energy Efficiency Market Transformation Organizations</h3>
          <ul>
            <li>
              <a href="http://www.ceeformt.org" target="_blank" rel="noopener noreferrer">
                Consortium for Energy Efficiency
              </a>
              (CEE)
            </li>
            <li>
              <a href="http://www.mwalliance.org" target="_blank" rel="noopener noreferrer">
                Midwest Energy Efficiency Alliance
              </a>{" "}
              (MEEA)
            </li>
            <li>
              <a href="http://www.neep.org" target="_blank" rel="noopener noreferrer">
                Northeast Energy Efficiency Partnerships
              </a>{" "}
              (NEEP)
            </li>
            <li>
              <a href="http://www.nwalliance.org" target="_blank" rel="noopener noreferrer">
                Northwest Energy Efficiency Alliance
              </a>{" "}
              (NEEA)
            </li>
          </ul>

          <h3>Referenced Reports</h3>
          <ul>
            <li>
              <a
                href="http://www.eren.doe.gov/buildings/codes_standards/notices/notc0032/screening_analysis.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Screening Analysis for EPACT-Covered Commercial HVAC and Water-Heating Equipment
              </a>
            </li>
            <li>
              <a
                href="http://www.eren.doe.gov/buildings/documents/pdfs/comhvac.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Energy Consumption Characteristics of Commercial Building HVAC Systems, Volume II: Thermal Distribution,
                Auxiliary Equipment, and Ventilation
              </a>
            </li>
            <li>
              <a
                href="http://tonto.eia.doe.gov/FTPROOT/consumption/062595.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                A Look at Commercial Buildings in 1995: Characteristics, Energy Consumption, and Energy Expenditures
              </a>
            </li>
          </ul>

          <h3>Utilities</h3>
          <ul>
            <li>
              <a href="http://www.fpl.com/savings/energy_advisor/PA_37.html" target="_blank" rel="noopener noreferrer">
                Florida Power and Light
              </a>{" "}
              (FPL)
            </li>
          </ul>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapActionToProps = {};

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(Links));
