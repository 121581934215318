import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { Analytics } from "utils/analytics";
import styles from "./styles";

class AdminView extends React.Component {
  componentDidMount() {
    Analytics.getInstance().event("Admin View", "Opened");
  }

  render() {
    const { classes, page } = this.props;
    return (
      <Grid className={classes.root} container alignContent="center" justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">{page.label}</Typography>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapActionToProps = {};

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(AdminView));
