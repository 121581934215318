import { dark, lighter, primaryShade, white } from "constants/palette";

const styles = (theme) => ({
  icon: {
    color: dark,
  },
  container: {
    width: "100%",
  },
  table: {},
  body: {
    "& tr:nth-child(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  header: {
    background: primaryShade,
  },
  headerLabel: {
    color: `${white} !important`,
  },
  row: {
    height: "48px",
    "& td": {
      border: 0,
    },
    "&:hover": {
      background: `${lighter} !important`,
    },
  },
  sort: {
    "& > svg": {
      fill: white,
    },
  },
  first: {
    paddingLeft: "20px",
  },
  flex: {
    display: "flex",
  },
  spacer: {
    flex: 1,
  },
});

export default styles;
