import { bold, gray } from "constants/palette";

const styles = {
  root: { padding: "15px" },
  header: {
    display: "flex",
    width: "1012px",
    paddingTop: "40px",
    paddingBottom: "40px",
  },
  headerTitle: { fontWeight: bold },
  delete: {},
  spacer: {
    flex: 1,
  },
  disabled: {
    background: `${gray} !important`,
  },
};

export default styles;
