const styles = {
  root: {
    outline: "none",
    position: "absolute",
    left: "50%",
    top: "50%"
  }
};

export default styles;
