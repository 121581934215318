import {
  generateAction,
  generateActions,
  generateSelector,
  generateSelectors,
  generateType,
  generateTypes,
} from "../util";

export const key = "calculations";

// active
export const ACTIVE = generateType(key, "active");
export const setActive = generateAction(ACTIVE);
export const selectActive = generateSelector(key, ACTIVE);

// sort calculations
export const SORT_CALCULATIONS = generateTypes(key, "sort");
export const [
  sortCalculations,
  sortCalculationsSuccess,
  sortCalculationsError,
  sortCalculationsBusy,
  sortCalculationsPoll,
] = generateActions(SORT_CALCULATIONS);
export const [
  selectSortCalculations,
  selectSortCalculationsError,
  selectSortCalculationsBusy,
  selectSortCalculationsPoll,
  selectSortCalculationsRequest,
] = generateSelectors(SORT_CALCULATIONS);
// fetch calculations
export const FETCH_CALCULATIONS = generateTypes(key, "list");
export const [
  fetchCalculations,
  fetchCalculationsSuccess,
  fetchCalculationsError,
  fetchCalculationsBusy,
  fetchCalculationsPoll,
] = generateActions(FETCH_CALCULATIONS);
export const [
  selectCalculations,
  selectCalculationsError,
  selectCalculationsBusy,
  selectCalculationsPoll,
  selectCalculationsRequest,
] = generateSelectors(FETCH_CALCULATIONS);
// fetch calculation
export const FETCH_CALCULATION = generateTypes(key, "current");
export const [
  fetchCalculation,
  fetchCalculationSuccess,
  fetchCalculationError,
  fetchCalculationBusy,
  fetchCalculationPoll,
] = generateActions(FETCH_CALCULATION);
export const [
  selectCalculation,
  selectCalculationError,
  selectCalculationBusy,
  selectCalculationPoll,
  selectCalculationRequest,
] = generateSelectors(FETCH_CALCULATION);
// update calculation
export const UPDATE_CALCULATION = generateTypes(key, "update");
export const [
  updateCalculation,
  updateCalculationSuccess,
  updateCalculationError,
  updateCalculationBusy,
] = generateActions(UPDATE_CALCULATION);
export const [
  selectUpdateCalculation,
  selectUpdateCalculationError,
  selectUpdateCalculationBusy,
  ,
  selectUpdateCalculationRequest,
] = generateSelectors(UPDATE_CALCULATION);
// remove calculation
export const REMOVE_CALCULATION = generateTypes(key, "remove");
export const [
  removeCalculation,
  removeCalculationSuccess,
  removeCalculationError,
  removeCalculationBusy,
] = generateActions(REMOVE_CALCULATION);
export const [
  selectRemoveCalculation,
  selectRemoveCalculationError,
  selectRemoveCalculationBusy,
  ,
  selectRemoveCalculationRequest,
] = generateSelectors(REMOVE_CALCULATION);
// create calculation
export const CREATE_CALCULATION = generateTypes(key, "create");
export const [
  createCalculation,
  createCalculationSuccess,
  createCalculationError,
  createCalculationBusy,
] = generateActions(CREATE_CALCULATION);
export const [
  selectCreateCalculation,
  selectCreateCalculationError,
  selectCreateCalculationBusy,
  ,
  selectCreateCalculationRequest,
] = generateSelectors(CREATE_CALCULATION);
