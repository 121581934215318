import { parseBoolean } from "../../utils/utils";
import { create, doMocked, isMocked, read, remove, update } from "../api";
import { key } from "./action";

export const SERVICE_ENDPOINT = "calculations";
export const SERVICE_ENDPOINT_READ_CALCULATION = `${SERVICE_ENDPOINT}/1/read`;
export const SERVICE_ENDPOINT_READ_CALCULATIONS = `${SERVICE_ENDPOINT}/read`;
export const SERVICE_ENDPOINT_CREATE_CALCULATION = `${SERVICE_ENDPOINT}/create`;
export const SERVICE_ENDPOINT_REMOVE_CALCULATION = `${SERVICE_ENDPOINT}/remove`;
export const SERVICE_ENDPOINT_UPDATE_CALCULATION = `${SERVICE_ENDPOINT}/update`;

export const readCalculations = () => {
  if (isMocked()) return doMocked(key, SERVICE_ENDPOINT_READ_CALCULATIONS);
  return read(`${SERVICE_ENDPOINT}`, null, parseBoolean(process.env.REACT_APP_LOGIN));
};

export const readCalculation = (uuid) => {
  if (isMocked()) return doMocked(key, SERVICE_ENDPOINT_READ_CALCULATION);
  return read(`${SERVICE_ENDPOINT}/${uuid}`, null, parseBoolean(process.env.REACT_APP_LOGIN));
};

export const removeCalculation = (id) => {
  if (isMocked()) return doMocked(key, SERVICE_ENDPOINT_REMOVE_CALCULATION);
  return remove(`${SERVICE_ENDPOINT}/${id}`, null, parseBoolean(process.env.REACT_APP_LOGIN));
};

export const updateCalculation = (id, body) => {
  if (isMocked()) return doMocked(key, SERVICE_ENDPOINT_UPDATE_CALCULATION);
  return update(`${SERVICE_ENDPOINT}/${id}`, body, null, parseBoolean(process.env.REACT_APP_LOGIN));
};

export const createCalculation = (body) => {
  if (isMocked()) return doMocked(key, SERVICE_ENDPOINT_CREATE_CALCULATION);
  return create(`${SERVICE_ENDPOINT}`, body, null, parseBoolean(process.env.REACT_APP_LOGIN));
};
