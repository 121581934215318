import { Calculate as CalculateIcon } from "icons";
import Root from "./Root";

const route = {
  sequence: 20,
  name: "Calculate",
  label: "Calculate",
  path: "/calculate",
  exact: false,
  component: Root,
  icon: CalculateIcon,
  admin: false,
  user: true,
  indent: true,
};

export default route;
