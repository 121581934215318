import { MuiDialog, MuiSection, MuiTextField } from "components";
import React, { Component, Fragment } from "react";
import { Tab, Tabs } from "@material-ui/core";
import { continueUser, loginUser, selectUser } from "controllers/user/action";

import { MuiFooter } from "..";
import { connect } from "react-redux";
import { createUser } from "controllers/users/action";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { parseBoolean } from "utils/utils";
import { Analytics } from "utils/analytics";

// false if server does not require passwords
const isValidatePassword = parseBoolean(process.env.REACT_APP_VALIDATE_PASSWORD);

// true if server creates new user on first login
const isCreateUser = parseBoolean(process.env.REACT_APP_CREATE_USER);

const isGuestLogin = parseBoolean(process.env.REACT_APP_GUEST_LOGIN);

const TabPanel = (props) => {
  const { children, index, value } = props;
  return <div hidden={value !== index}>{children}</div>;
};

class MuiLogin extends Component {
  state = {
    email: "",
    password: isValidatePassword ? "" : "NoPasswordRequired",
    repeat: isValidatePassword ? "" : "NoPasswordRequired",
    value: 0,
  };

  componentDidMount() {
    const { noContinue } = this.props;
    if (!noContinue) {
      this.props.continueUser();
    }
    Analytics.getInstance().event("Login", "Opened");
  }

  validateField = () => {
    const { email, password, repeat, value } = this.state;
    if (value === 0) {
      return email && password;
    } else {
      const emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) !== null;
      return email && emailValid && password && password === repeat;
    }
  };

  handleTabChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleLogin = () => {
    if (this.validateField()) {
      const { email, password } = this.state;
      this.props.loginUser({ email, password });
      this.setState({ password: "" });
    }
    return false;
  };

  handleGuest = () => {
    this.props.loginUser({ email: process.env.REACT_APP_GUEST_EMAIL, password: process.env.REACT_APP_GUEST_PASSWORD });
    this.setState({ password: "" });
    return false;
  };

  handleNewAccount = () => {
    if (this.validateField()) {
      const { email, password } = this.state;
      this.props.createUser({ email, password });
      this.setState({ value: 0, password: "", repeat: "" });
    }
  };

  handleChange = (key) => (event) => {
    this.setState({ [key]: event.target.value });
  };

  buttons = () => {
    if (this.state.value === 0) {
      return [
        {
          disabled: !this.validateField(),
          label: isGuestLogin ? "Login as User" : "Login",
          onClick: this.handleLogin,
          variant: "primary",
          type: "submit",
        },
        ...(isGuestLogin
          ? [{ disabled: false, label: "Login as Guest", onClick: this.handleGuest, variant: "", type: "submit" }]
          : []),
      ];
    } else if (this.state.value === 1) {
      return [
        {
          label: "Create New Account",
          disabled: !this.validateField(),
          onClick: this.handleNewAccount,
          variant: "primary",
          type: "submit",
        },
      ];
    }
  };

  render() {
    const { classes, user, busy, onClose } = this.props;
    const { email, password, repeat } = this.state;
    if (user) {
      return null;
    }
    return (
      <Fragment>
        <MuiDialog
          title={`${process.env.REACT_APP_TITLE}`}
          open={!user}
          busy={busy}
          buttons={this.buttons()}
          onClose={onClose}
          disablePadding
        >
          <Fragment>
            <Tabs
              className={classes.tabs}
              value={this.state.value}
              onChange={this.handleTabChange}
              indicatorColor="primary"
            >
              <Tab label="Login" />
              {!isCreateUser && <Tab label="New Account" />}
            </Tabs>
            <TabPanel value={this.state.value} index={0}>
              <MuiSection>
                <MuiTextField id="email" placeholder="Email" value={email} onChange={this.handleChange("email")} />
                {isValidatePassword && (
                  <MuiTextField
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={this.handleChange("password")}
                    type="password"
                  />
                )}
              </MuiSection>
            </TabPanel>
            {!isCreateUser && (
              <TabPanel value={this.state.value} index={1}>
                <MuiSection>
                  <MuiTextField id="email" placeholder="Email" value={email} onChange={this.handleChange("email")} />
                  {isValidatePassword && (
                    <MuiTextField
                      id="new-password"
                      placeholder="New Password"
                      value={password}
                      onChange={this.handleChange("password")}
                      type="password"
                    />
                  )}
                  {isValidatePassword && (
                    <MuiTextField
                      id="repeat-password"
                      placeholder="Retype Password"
                      value={repeat}
                      onChange={this.handleChange("repeat")}
                      type="password"
                    />
                  )}
                </MuiSection>
              </TabPanel>
            )}
          </Fragment>
        </MuiDialog>
        <MuiFooter />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: selectUser(state),
});

const mapActionToProps = {
  continueUser,
  loginUser,
  createUser,
};

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(MuiLogin));
