import {
  Clear as ClearIcon,
  Refresh as RefreshIcon,
  Search as SearchIcon,
} from "@material-ui/icons";
import { ConditionalWrapper, getAriaLabel } from "utils/utils";
import { InputAdornment, TextField, Tooltip } from "@material-ui/core";

import MuiIconButton from "components/MuiIconButton";
import MuiInfo from "components/MuiInfo";
import MuiLabel from "./MuiLabel";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import clsx from "clsx";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";

const getFaultName = (props) => {
  const { classes, error, warning, info } = props;
  if (error) {
    return classes.error;
  } else if (warning) {
    return classes.warning;
  } else if (info) {
    return classes.info;
  }
};

const getAdornmentStart = (props) => {
  const { classes, adornment, busy } = props;
  if (busy) {
    return <RefreshIcon className="icon-spin" color="primary" />;
  } else if (adornment) {
    return adornment;
  } else {
    return <SearchIcon className={classes.icon} />;
  }
};

const getAdornmentEnd = (props) => {
  const { classes, value } = props;
  if (_.isEmpty(value)) {
    return <span />;
  } else {
    return (
      <MuiIconButton
        aria-label={getAriaLabel(props)}
        onClick={() => props.onChange(null, "")}
        style={{ order: 1 }}
      >
        <ClearIcon className={classes.icon} />
      </MuiIconButton>
    );
  }
};

const MuiSearchField = (props) => {
  const {
    classes,
    className,
    header,
    detail,
    tooltip,
    required,
    placeholder,
  } = props;
  return (
    <ConditionalWrapper
      condition={Boolean(header)}
      wrapper={(c) => (
        <div>
          <MuiLabel
            className={!_.isEmpty(detail) ? classes.inline : undefined}
            header={header}
            required={required}
          />
          {!_.isEmpty(detail) ? <MuiInfo title={detail} /> : undefined}
          {c}
        </div>
      )}
    >
      <Tooltip placement="bottom" title={tooltip ? tooltip : ""}>
        <TextField
          {..._.omit(props, [
            "classes",
            "className",
            "header",
            "tooltip",
            "placeholder",
            "adornment",
            "info",
            "warning",
            "busy",
          ])}
          className={clsx(className, classes.textField, getFaultName(props))}
          aria-label={getAriaLabel(props)}
          inputProps={{ "aria-label": getAriaLabel(props) }}
          size="small"
          variant="outlined"
          fullWidth
          placeholder={placeholder}
          InputProps={{
            className: classes.searchInput,
            style: { paddingLeft: "4px", paddingRight: "4px" },
            startAdornment: (
              <InputAdornment position="start">
                {getAdornmentStart(props)}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {getAdornmentEnd(props)}
              </InputAdornment>
            ),
          }}
        />
      </Tooltip>
    </ConditionalWrapper>
  );
};

MuiSearchField.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  detail: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string,
  adornment: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  required: PropTypes.bool,
  busy: PropTypes.bool,
};

export default withStyles(styles)(MuiSearchField);
