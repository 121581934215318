import { error, errorTint, gray, primaryTint, white } from "constants/palette";

const styles = {
  root: {
    paddingTop: "30px",
    paddingRight: "30px",
    paddingBottom: "30px",
  },
  content: {
    padding: "30px",
  },
  center: {
    textAlign: "center",
  },
  description: {
    display: "inline-flex",
    padding: "10px",
    color: white,
    backgroundColor: primaryTint,
    textAlign: "center",
  },
  text: {
    paddingLeft: "10px",
  },
  header: {},
  padding: {
    padding: "10px",
  },
  form: {
    width: "100%",
  },
  formGroup: {
    display: "flex",
    flexDirection: "row",
  },
  formEntry: {
    flex: 1,
  },
  formInfo: {},
  infoIcon: {
    margin: "auto",
    textAlign: "center",
    top: "50%",
    bottom: "50%",
  },
  section: {
    display: "flex",
  },
  sectionLabel: {
    flex: 1,
  },
  sectionIcon: {
    flex: 0,
  },
  sectionError: {
    background: errorTint,
    borderRadius: "4px",
    border: `solid ${errorTint} 4px`,
  },
  sectionInfo: {
    background: gray,
    borderRadius: "4px",
    border: `solid ${gray} 4px`,
  },
  message: {
    width: "100%",
    display: "flex",
    textAlign: "left",
  },
  snackbar: {
    display: "inline-block",
    backgroundColor: errorTint,
  },
  negative: {
    color: error,
  },
};

export default styles;
