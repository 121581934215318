import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import React from "react";

class MuiLink extends React.Component {
  render() {
    return <Button component={Link} {...this.props} />;
  }
}

export default MuiLink;
