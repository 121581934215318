import { ConditionalWrapper } from "utils/utils";
import MuiLabel from "./MuiLabel";
import PropTypes from "prop-types";
import React from "react";
import { TextField } from "@material-ui/core";
import _ from "lodash";
import clsx from "clsx";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";

const MuiTextArea = (props) => {
  const {
    classes,
    className,
    header,
    required,
    rows,
    placeholder,
    "aria-label": ariaLabel,
  } = props;

  return (
    <ConditionalWrapper
      condition={Boolean(header)}
      wrapper={(c) => (
        <div>
          <MuiLabel header={header} required={required} />
          {c}
        </div>
      )}
    >
      <TextField
        {..._.omit(props, ["classes", "className", "placeholder", "header"])}
        className={clsx(className, classes.textArea)}
        aria-label={ariaLabel ? ariaLabel : header ? header : placeholder}
        size="medium"
        variant="outlined"
        fullWidth
        multiline
        rowsMax={rows}
        placeholder={placeholder}
      />
    </ConditionalWrapper>
  );
};

MuiTextArea.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  rows: PropTypes.number.isRequired,
};

export default withStyles(styles)(MuiTextArea);
