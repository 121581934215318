import { error, gray, info, primary, verified, warning } from "constants/palette";

const styles = {
  toolbar: {
    flex: "1 0 auto",
    minHeight: 0,
  },
  stepper: {
    flex: "1 0 auto",
    background: "transparent",
  },
  disableGutters: {
    padding: 0,
  },
  stepLabel: {
    "& > span": {
      paddingRight: 0,
    },
  },
  disabled: {
    color: gray,
  },
  primary: {
    color: primary,
  },
  verified: {
    color: verified,
  },
  info: {
    color: info,
  },
  warning: {
    color: warning,
  },
  error: {
    color: error,
  },
};

export default styles;
