import _ from "lodash";

class Type {
  /**
   * Get a copy of the values.
   * Name is the lowercase unique key.
   * Label is the pretty formatted unique key.
   *
   * @returns values
   */
  values = undefined;

  /**
   * Parse the supplied value and return the located type or undefined if not located.
   *
   * @param {Number|String} value
   * @returns type or undefined
   */
  parse = function(value) {
    if (_.isNumber(value)) {
      return this.values()[value];
    } else if (_.has(value, ["name"])) {
      return this.parse(value.name);
    }
    return this.values().find((v) => [v.name, v.label].includes(value));
  };
}

export default Type;
