import { Accordion, AccordionDetails, AccordionSummary, Grid, MenuItem, Tooltip, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import mixin from "utils/mixin";
import _ from "lodash";
import { ExpandMore, Info as InfoIcon } from "@material-ui/icons";
import { MuiSection, MuiSelect, MuiTextField } from "components";
import {
  days,
  compressors,
  economizers,
  fans,
  heatings,
  buildings,
  windows,
  orientations,
  climates,
  zones,
  Baseline,
  Upgraded,
} from "rtucc-common/constants";
import clsx from "clsx";
import { buildCalculationLabel, buildRtucLabel, buildScheduleLabel } from "controllers/calculations/util";
import { defaultClientValues, disabledFields, errorMessages, toClientValues, toServerValues } from "../util";
import { Analytics } from "utils/analytics";

const dayLabels = days.values().map((day) => day.name);

class InputView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      expanded: "calculation",
      ...defaultClientValues(),
    };
    _.merge(this, mixin);
  }

  componentDidMount() {
    const { calculation } = this.props;
    Analytics.getInstance().event("Input View", "Opened");
    const temp = toClientValues(calculation);
    this.setState(temp);
  }

  handleUpdate(key, state) {
    const temp = _.merge({}, this.state, state);
    const errors = errorMessages(temp, key, (v) => this.setState(v));
    const update = {};
    switch (key) {
      case `compressor${Baseline}`:
        const nb = _.isEqual(compressors.parse(temp[`compressor${Baseline}`]), compressors.parse("single-speed"))
          ? 1
          : 2;
        if (temp[`numberOfSpeeds${Baseline}`] !== nb) {
          update[`numberOfSpeeds${Baseline}`] = nb;
        }
        break;
      case `compressor${Upgraded}`:
        const nu = _.isEqual(compressors.parse(temp[`compressor${Upgraded}`]), compressors.parse("single-speed"))
          ? 1
          : 2;
        if (temp[`numberOfSpeeds${Upgraded}`] !== nu) {
          update[`numberOfSpeeds${Upgraded}`] = nu;
        }
        break;
      default:
    }
    this.setState({ errors: _.assign({}, this.state.errors, errors), ...update }, () => {
      const valid = this.isValid(errors);
      if (valid) {
        this.updateCalculation();
      }
      this.props.onValid(valid);
    });
  }

  isValid(errors) {
    return _.isEmpty(Object.values(_.assign({}, this.state.errors, errors)).filter((v) => v));
  }

  updateCalculation() {
    const update = toServerValues(this.state);
    this.props.updateCalculation(update);
  }

  buildSchedule() {
    const temp = _.pick(
      this.state,
      _.concat(
        dayLabels.map((d) => `${d}Start`),
        dayLabels.map((d) => `${d}End`)
      )
    );
    dayLabels.forEach((day) => (temp[`${day}Start`] = parseInt(temp[`${day}Start`])));
    dayLabels.forEach((day) => (temp[`${day}End`] = parseInt(temp[`${day}End`]) + 12));
    return temp;
  }

  insertLink(field, value, link) {
    if (link && value.includes("{link}")) {
      const parts = /^(.*)\{link\}(.*)$/s.exec(value);
      return (
        <span key={`link-${field}`}>
          {parts[1]}
          <a href={link} target="_blank" rel="noopener noreferrer">
            link
          </a>
          {parts[2]}
        </span>
      );
    } else {
      return value;
    }
  }

  formatTooltip(field) {
    const { text } = this.props;
    let temp = field;
    if (field.endsWith("Start") || field.endsWith("End")) {
      temp = "day";
    } else if (field.endsWith(Upgraded) || field.endsWith(Baseline)) {
      temp = /^([a-zA-Z]+)(?:Upgraded|Baseline)$/.exec(field)[1];
    }
    const tooltip = _.get(text, [temp], `No description for field: ${temp}`);
    const link = _.get(text, ["metadata", temp, "link"]);
    let component = (
      <span>
        {tooltip
          .split("**")
          .map((v, i) => (i % 2 === 0 ? this.insertLink(field, v, link) : <b key={`bold-${i}`}>{v}</b>))}
      </span>
    );
    return component;
  }

  renderRow(field, label, info, editor, error) {
    const { classes } = this.props;
    const tooltip = this.formatTooltip(field);
    return (
      <Fragment key={field}>
        <Grid item xs={3}>
          <div className={classes.section}>
            <div className={classes.sectionLabel}>
              <Typography>{label}</Typography>
            </div>
            <div className={classes.sectionIcon}>
              <Tooltip title={tooltip} interactive>
                <InfoIcon className={classes.infoIcon} color="primary" />
              </Tooltip>
            </div>
          </div>
        </Grid>
        <Grid item xs={3}>
          {editor}
        </Grid>
        <Grid item xs={6}>
          <div className={clsx(classes.section, error ? classes.sectionError : classes.sectionInfo)}>
            <Typography>{error || info}</Typography>
          </div>
        </Grid>
      </Fragment>
    );
  }

  renderGeneral(disabled) {
    const {
      errors,
      label,
      building,
      zone,
      window,
      orientation,
      climate,
      coolingSetpoint,
      coolingSetback,
      heatingSetpoint,
      heatingSetback,
      oversizeFactor,
      coolingCapacity,
      blendedElectricityRate,
      blendedNaturalGasRate,
      realDiscountRate,
      rtuLifetime,
    } = this.state;
    const buildingType = _.assign(
      { zones: zones.values(), orientations: orientations.values() },
      buildings.parse(building)
    );
    return (
      <MuiSection header="" bordered>
        <Grid container alignItems="center" alignContent="center" justifyContent="flex-start" spacing={2}>
          {this.renderRow(
            "label",
            "Label",
            <span>&nbsp;</span>,
            <MuiTextField
              id="label"
              value={label}
              onChange={this.handleChange("label")}
              disabled={disabled.includes("label")}
            />
          )}
          {this.renderRow(
            "building",
            "Building Type",
            <span>&nbsp;</span>,
            <MuiSelect
              id="building"
              value={building}
              renderValue={(v) => _.get(buildings.parse(v), "label")}
              onChange={this.handleChange("building")}
              disabled={disabled.includes("building")}
              fullWidth
            >
              {buildings
                .values()
                .filter((v) => v.valid)
                .map((o) => (
                  <MenuItem key={`building-item-${o.name}`} value={o.label}>
                    {o.label}
                  </MenuItem>
                ))}
            </MuiSelect>
          )}
          {this.renderRow(
            "zone",
            "Zone Type",
            <span>&nbsp;</span>,
            <MuiSelect
              id="zone"
              value={zone}
              renderValue={(v) => _.get(zones.parse(v), "label")}
              onChange={this.handleChange("zone")}
              disabled={disabled.includes("zone")}
              fullWidth
            >
              {buildingType.zones.map((o) => (
                <MenuItem key={`zone-item-${o.name}`} value={o.label}>
                  {o.label}
                </MenuItem>
              ))}
            </MuiSelect>,
            _.get(errors, ["zone"], "")
          )}
          {this.renderRow(
            "window",
            "Window Area",
            <span>&nbsp;</span>,
            <MuiSelect
              id="window"
              value={window}
              renderValue={(v) => _.get(windows.parse(v), "label")}
              onChange={this.handleChange("window")}
              disabled={disabled.includes("window")}
              fullWidth
            >
              {windows.values().map((o) => (
                <MenuItem key={`window-item-${o.name}`} value={o.label}>
                  {o.label}
                </MenuItem>
              ))}
            </MuiSelect>
          )}
          {this.renderRow(
            "orientation",
            "Building Orientation",
            <span>&nbsp;</span>,
            <MuiSelect
              id="orientation"
              value={orientation}
              renderValue={(v) => _.get(orientations.parse(v), "label")}
              onChange={this.handleChange("orientation")}
              disabled={disabled.includes("orientation")}
              fullWidth
            >
              {buildingType.orientations.map((o) => (
                <MenuItem key={`orientation-item-${o.name}`} value={o.label}>
                  {o.label}
                </MenuItem>
              ))}
            </MuiSelect>,
            _.get(errors, ["orientation"], "")
          )}
          {this.renderRow(
            "climate",
            "Climate",
            <span>&nbsp;</span>,
            <MuiSelect
              id="climate"
              value={climate}
              renderValue={(v) => `${_.get(climates.parse(v), "label")} - ${climates.findLocation(v)}`}
              onChange={this.handleChange("climate")}
              disabled={disabled.includes("climate")}
              fullWidth
            >
              {climates
                .values()
                .filter((v) => v.valid)
                .map((o) => (
                  <MenuItem key={`climate-item-${o.name}`} value={o.label}>
                    {`${o.label} - ${climates.findLocation(o.name)} (${climates.findDescription(o.name)})`}
                  </MenuItem>
                ))}
            </MuiSelect>
          )}
          {this.renderRow(
            "coolingSetpoint",
            "Cooling Setpoint",
            <span>&nbsp;</span>,
            <MuiTextField
              id="coolingSetpoint"
              value={coolingSetpoint}
              onChange={this.handleChange("coolingSetpoint")}
              disabled={disabled.includes("coolingSetpoint")}
              error={Boolean(_.get(errors, ["coolingSetpoint"]))}
              adornment="&deg;F"
            />,
            _.get(errors, ["coolingSetpoint"], "")
          )}
          {this.renderRow(
            "coolingSetback",
            "Cooling Setback",
            <span>&nbsp;</span>,
            <MuiTextField
              id="coolingSetback"
              value={coolingSetback}
              onChange={this.handleChange("coolingSetback")}
              disabled={disabled.includes("coolingSetback")}
              error={Boolean(_.get(errors, ["coolingSetback"]))}
              adornment="&deg;F"
            />,
            _.get(errors, ["coolingSetback"], "")
          )}
          {this.renderRow(
            "heatingSetpoint",
            "Heating Setpoint",
            <span>&nbsp;</span>,
            <MuiTextField
              id="heatingSetpoint"
              value={heatingSetpoint}
              onChange={this.handleChange("heatingSetpoint")}
              disabled={disabled.includes("heatingSetpoint")}
              error={Boolean(_.get(errors, ["heatingSetpoint"]))}
              adornment="&deg;F"
            />,
            _.get(errors, ["heatingSetpoint"], "")
          )}
          {this.renderRow(
            "heatingSetback",
            "Heating Setback",
            <span>&nbsp;</span>,
            <MuiTextField
              id="heatingSetback"
              value={heatingSetback}
              onChange={this.handleChange("heatingSetback")}
              disabled={disabled.includes("heatingSetback")}
              error={Boolean(_.get(errors, ["heatingSetback"]))}
              adornment="&deg;F"
            />,
            _.get(errors, ["heatingSetback"], "")
          )}
          {this.renderRow(
            "oversizeFactor",
            "Oversize Factor",
            <span>&nbsp;</span>,
            <MuiTextField
              id="oversizeFactor"
              value={oversizeFactor}
              onChange={this.handleChange("oversizeFactor")}
              disabled={disabled.includes("oversizeFactor")}
              error={Boolean(_.get(errors, ["oversizeFactor"]))}
              adornment="x"
            />,
            _.get(errors, ["oversizeFactor"], "")
          )}
          {this.renderRow(
            "coolingCapacity",
            "Cooling Capacity",
            <span>&nbsp;</span>,
            <MuiTextField
              id="coolingCapacity"
              value={coolingCapacity}
              onChange={this.handleChange("coolingCapacity")}
              disabled={disabled.includes("coolingCapacity")}
              error={Boolean(_.get(errors, ["coolingCapacity"]))}
              adornment="Btu/h"
            />,
            _.get(errors, ["coolingCapacity"], "")
          )}
          {this.renderRow(
            "blendedElectricityRate",
            "Electricity Rate",
            <span>&nbsp;</span>,
            <MuiTextField
              id="blendedElectricityRate"
              value={blendedElectricityRate}
              onChange={this.handleChange("blendedElectricityRate")}
              disabled={disabled.includes("blendedElectricityRate")}
              error={Boolean(_.get(errors, ["blendedElectricityRate"]))}
              adornment="$/kWh"
            />,
            _.get(errors, ["blendedElectricityRate"], "")
          )}
          {this.renderRow(
            "blendedNaturalGasRate",
            "Natural Gas Rate",
            <span>&nbsp;</span>,
            <MuiTextField
              id="blendedNaturalGasRate"
              value={blendedNaturalGasRate}
              onChange={this.handleChange("blendedNaturalGasRate")}
              disabled={disabled.includes("blendedNaturalGasRate")}
              error={Boolean(_.get(errors, ["blendedNaturalGasRate"]))}
              adornment="$/ccf"
            />,
            _.get(errors, ["blendedNaturalGasRate"], "")
          )}
          {this.renderRow(
            "realDiscountRate",
            "Real Discount Rate",
            <span>&nbsp;</span>,
            <MuiTextField
              id="realDiscountRate"
              value={realDiscountRate}
              onChange={this.handleChange("realDiscountRate")}
              disabled={disabled.includes("realDiscountRate")}
              error={Boolean(_.get(errors, ["realDiscountRate"]))}
              adornment="%"
            />,
            _.get(errors, ["realDiscountRate"], "")
          )}
          {this.renderRow(
            "rtuLifetime",
            "RTU Lifetime",
            <span>&nbsp;</span>,
            <MuiTextField
              id="rtuLifetime"
              value={rtuLifetime}
              onChange={this.handleChange("rtuLifetime")}
              disabled={disabled.includes("rtuLifetime")}
              error={Boolean(_.get(errors, ["rtuLifetime"]))}
              adornment="years"
            />,
            _.get(errors, ["rtuLifetime"], "")
          )}
        </Grid>
      </MuiSection>
    );
  }

  renderTimeRange(day, disabled) {
    const { [`${day}Start`]: start, [`${day}End`]: end, errors } = this.state;
    return (
      <Fragment key={`${day}-start`}>
        {this.renderRow(
          `${day}Start`,
          `${_.capitalize(day)} Start`,
          <span>&nbsp;</span>,
          <MuiTextField
            id={`${day}-start`}
            value={start}
            onChange={this.handleChange(`${day}Start`)}
            disabled={disabled.includes(`${day}Start`)}
            error={Boolean(_.get(errors, [`${day}Start`]))}
            adornment="am"
          />,
          _.get(errors, [`${day}Start`], "")
        )}
        {this.renderRow(
          `${day}End`,
          `${_.capitalize(day)} End`,
          <span>&nbsp;</span>,
          <MuiTextField
            id={`${day}-end`}
            value={end}
            onChange={this.handleChange(`${day}End`)}
            disabled={disabled.includes(`${day}End`)}
            error={Boolean(_.get(errors, [`${day}End`]))}
            adornment="pm"
          />,
          _.get(errors, [`${day}End`], "")
        )}
      </Fragment>
    );
  }

  renderSchedule(disabled) {
    return (
      <MuiSection header="Schedule" bordered>
        <Grid container alignItems="center" alignContent="center" justifyContent="flex-start" spacing={2}>
          {dayLabels.map((day) => this.renderTimeRange(day, disabled))}
        </Grid>
      </MuiSection>
    );
  }

  renderSection(type, disabled) {
    const {
      errors,
      [`compressor${type}`]: compressor,
      [`numberOfSpeeds${type}`]: numberOfSpeeds,
      [`fan${type}`]: fan,
      [`eer${type}`]: eer,
      [`heating${type}`]: heating,
      [`heatingCop${type}`]: heatingCop,
      [`minOaFraction${type}`]: minOaFraction,
      [`economizer${type}`]: economizer,
      [`maximumLimitDryBulb${type}`]: maximumLimitDryBulb,
      [`capitalCost${type}`]: capitalCost,
    } = this.state;
    return (
      <MuiSection header={type} bordered>
        <Grid container alignItems="center" alignContent="center" justifyContent="flex-start" spacing={2}>
          {this.renderRow(
            `compressor${type}`,
            "Compressor Speed",
            <span>&nbsp;</span>,
            <MuiSelect
              id={`compressor${type}`}
              value={compressor}
              renderValue={(v) => _.get(compressors.parse(v), "label")}
              onChange={this.handleChange(`compressor${type}`)}
              disabled={disabled.includes(`compressor${type}`)}
              fullWidth
            >
              {compressors.values().map((o) => (
                <MenuItem key={`compressor${type}-item-${o.name}`} value={o.label}>
                  {o.label}
                </MenuItem>
              ))}
            </MuiSelect>
          )}
          {this.renderRow(
            `numberOfSpeeds${type}`,
            "Number Of Compressor Speeds",
            <span>&nbsp;</span>,
            <MuiTextField
              id={`numberOfSpeeds${type}`}
              value={numberOfSpeeds}
              onChange={this.handleChange(`numberOfSpeeds${type}`)}
              disabled={disabled.includes(`numberOfSpeeds${type}`)}
              error={Boolean(_.get(errors, [`numberOfSpeeds${type}`]))}
            />,
            _.get(errors, [`numberOfSpeeds${type}`], "")
          )}
          {this.renderRow(
            `fan${type}`,
            "Fan Type",
            <span>&nbsp;</span>,
            <MuiSelect
              id={`fan${type}`}
              value={fan}
              renderValue={(v) => _.get(fans.parse(v), "label")}
              onChange={this.handleChange(`fan${type}`)}
              disabled={disabled.includes(`fan${type}`)}
              fullWidth
            >
              {fans.values().map((o) => (
                <MenuItem key={`fan${type}-item-${o.name}`} value={o.label}>
                  {o.label}
                </MenuItem>
              ))}
            </MuiSelect>
          )}
          {this.renderRow(
            `eer${type}`,
            "Energy Efficiency Ratio",
            <span>&nbsp;</span>,
            <MuiTextField
              id={`eer${type}`}
              value={eer}
              onChange={this.handleChange(`eer${type}`)}
              disabled={disabled.includes(`eer${type}`)}
              error={Boolean(_.get(errors, [`eer${type}`]))}
              adornment=":1"
            />,
            _.get(errors, [`eer${type}`], "")
          )}
          {this.renderRow(
            `heating${type}`,
            "Heating Equipment",
            <span>&nbsp;</span>,
            <MuiSelect
              id={`heating${type}`}
              value={heating}
              renderValue={(v) => _.get(heatings.parse(v), "label")}
              onChange={this.handleChange(`heating${type}`)}
              disabled={disabled.includes(`heating${type}`)}
              fullWidth
            >
              {heatings.values().map((o) => (
                <MenuItem key={`heating${type}-item-${o.name}`} value={o.label}>
                  {o.label}
                </MenuItem>
              ))}
            </MuiSelect>
          )}
          {this.renderRow(
            `heatingCop${type}`,
            "Heating Efficiency",
            <span>&nbsp;</span>,
            <MuiTextField
              id={`heatingCop${type}`}
              value={heatingCop}
              onChange={this.handleChange(`heatingCop${type}`)}
              disabled={disabled.includes(`heatingCop${type}`)}
              error={Boolean(_.get(errors, [`heatingCop${type}`]))}
              adornment=":1"
            />,
            _.get(errors, [`heatingCop${type}`], "")
          )}
          {this.renderRow(
            `minOaFraction${type}`,
            "Minimum OA Fraction",
            <span>&nbsp;</span>,
            <MuiTextField
              id={`minOaFraction${type}`}
              value={minOaFraction}
              onChange={this.handleChange(`minOaFraction${type}`)}
              disabled={disabled.includes(`minOaFraction${type}`)}
              error={Boolean(_.get(errors, [`minOaFraction${type}`]))}
            />,
            _.get(errors, [`minOaFraction${type}`], "")
          )}
          {this.renderRow(
            `economizer${type}`,
            "Economizer",
            <span>&nbsp;</span>,
            <MuiSelect
              id={`economizer${type}`}
              value={economizer}
              renderValue={(v) => _.get(economizers.parse(v), "label")}
              onChange={this.handleChange(`economizer${type}`)}
              disabled={disabled.includes(`economizer${type}`)}
              fullWidth
            >
              {economizers.values().map((o) => (
                <MenuItem key={`economizer${type}-item-${o.name}`} value={o.label}>
                  {o.label}
                </MenuItem>
              ))}
            </MuiSelect>
          )}
          {this.renderRow(
            `maximumLimitDryBulb${type}`,
            "Economizer High Limit",
            <span>&nbsp;</span>,
            <MuiTextField
              id={`maximumLimitDryBulb${type}`}
              value={maximumLimitDryBulb}
              onChange={this.handleChange(`maximumLimitDryBulb${type}`)}
              disabled={disabled.includes(`maximumLimitDryBulb${type}`)}
              error={Boolean(_.get(errors, [`maximumLimitDryBulb${type}`]))}
              adornment="&deg;F"
            />,
            _.get(errors, [`maximumLimitDryBulb${type}`], "")
          )}
          {this.renderRow(
            `capitalCost${type}`,
            "Capital Cost",
            <span>&nbsp;</span>,
            <MuiTextField
              id={`capitalCost${type}`}
              value={capitalCost}
              onChange={this.handleChange(`capitalCost${type}`)}
              disabled={disabled.includes(`capitalCost${type}`)}
              error={Boolean(_.get(errors, [`capitalCost${type}`]))}
              adornment="$"
            />,
            _.get(errors, [`capitalCost${type}`], "")
          )}
        </Grid>
      </MuiSection>
    );
  }

  renderCollapsable(id, label, children) {
    const { classes } = this.props;
    const { expanded } = this.state;
    return (
      <Accordion
        expanded={expanded === id}
        onChange={() => this.handleChange("expanded")(null, id !== expanded ? id : "")}
      >
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls={`${label}-controls`} id={id}>
          <Typography className={classes.heading}>{label}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    );
  }

  render() {
    const { classes } = this.props;
    const disabled = disabledFields(this.state);
    return (
      <Fragment>
        {this.renderCollapsable("calculation", buildCalculationLabel(this.state), this.renderGeneral(disabled))}
        <div className={classes.padding} />
        {this.renderCollapsable(
          "schedule",
          `Schedule: ${buildScheduleLabel(this.buildSchedule())}`,
          this.renderSchedule(disabled)
        )}
        <div className={classes.padding} />
        {this.renderCollapsable(
          "baseline",
          buildRtucLabel(this.state, Baseline),
          this.renderSection(Baseline, disabled)
        )}
        <div className={classes.padding} />
        {this.renderCollapsable(
          "upgraded",
          buildRtucLabel(this.state, Upgraded),
          this.renderSection(Upgraded, disabled)
        )}
      </Fragment>
    );
  }
}

export default InputView;
