import { History as HistoryIcon } from "@material-ui/icons";
import Root from "./Root";

const route = {
  sequence: 80,
  name: "History",
  label: "History",
  path: "/history",
  exact: true,
  component: Root,
  icon: HistoryIcon,
  admin: false,
  user: true,
  indent: true,
};

export default route;
