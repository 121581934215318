import { Button, Tooltip } from "@material-ui/core";
import { ConditionalWrapper, getAriaLabel } from "utils/utils";

import React from "react";
import _ from "lodash";
import clsx from "clsx";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";

/**
 * Abstracted Button
 * disabled - boolean
 * onClick - function
 * variant - string [cancel, error, info, outlined, primary, warning]
 * @param {object} props
 */
const MuiButton = (props) => {
  const {
    classes,
    className,
    disabled,
    tooltip,
    variant,
    width,
    children,
  } = props;
  const classNames = clsx([
    className,
    classes.default,
    classes[variant],
    width,
    disabled && "disabled",
  ]);
  return (
    <ConditionalWrapper
      condition={!(width === "xl" || !tooltip)}
      wrapper={(c) => (
        <Tooltip placement="bottom" title={tooltip}>
          {c}
        </Tooltip>
      )}
    >
      <Button
        {..._.omit(props, [
          "classes",
          "className",
          "header",
          "tooltip",
          "variant",
          "width",
          "children",
          "aria-label",
        ])}
        className={classNames}
        aria-label={getAriaLabel(props)}
        disableRipple
      >
        {children}
      </Button>
    </ConditionalWrapper>
  );
};

export default withStyles(styles)(MuiButton);
