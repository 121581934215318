import { ConditionalWrapper } from "utils/utils";
import { KeyboardDatePicker } from "@material-ui/pickers";
import MuiLabel from "./MuiLabel";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import clsx from "clsx";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";

const MuiDatePicker = (props) => {
  const {
    classes,
    className,
    header,
    required,
    placeholder,
    "aria-label": ariaLabel,
  } = props;

  return (
    <ConditionalWrapper
      condition={Boolean(header)}
      wrapper={(c) => (
        <div>
          <MuiLabel header={header} required={required} />
          {c}
        </div>
      )}
    >
      <KeyboardDatePicker
        {..._.omit(props, ["classes", "className", "header", "placeholder"])}
        className={clsx(className, classes.datePicker)}
        aria-label={ariaLabel ? ariaLabel : header ? header : placeholder}
        disableToolbar
        size="medium"
        variant="inline"
        inputVariant="outlined"
        margin="normal"
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        fullWidth
        placeholder={placeholder}
      />
    </ConditionalWrapper>
  );
};

MuiDatePicker.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
};

export default withStyles(styles)(MuiDatePicker);
