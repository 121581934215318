import { key as busyKey } from "./busy/action";
import busyReducer from "./busy/reducer";
import { combineReducers } from "redux-immutable";
import { key as commonKey } from "./common/action";
import commonReducer from "./common/reducer";
import { key as errorKey } from "./error/action";
import errorReducer from "./error/reducer";
import { key as pollKey } from "./poll/action";
import pollReducer from "./poll/reducer";
import { key as userKey } from "./user/action";
import userReducer from "./user/reducer";
import { key as usersKey } from "./users/action";
import usersReducer from "./users/reducer";
import { key as textKey } from "./text/action";
import textReducer from "./text/reducer";
import { key as calculationsKey } from "./calculations/action";
import calculationsReducer from "./calculations/reducer";

export default combineReducers({
  [userKey]: userReducer,
  [usersKey]: usersReducer,
  [busyKey]: busyReducer,
  [errorKey]: errorReducer,
  [pollKey]: pollReducer,
  [commonKey]: commonReducer,
  [textKey]: textReducer,
  [calculationsKey]: calculationsReducer,
});
