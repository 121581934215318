import {
  CONTINUE_USER,
  FETCH_USER,
  LOGIN_USER,
  LOGOUT_USER,
  REMOVE_USER,
  UPDATE_USER,
  continueUserBusy,
  continueUserError,
  fetchUserBusy,
  fetchUserError,
  fetchUserSuccess,
  loginUserBusy,
  loginUserError,
  loginUserSuccess,
  logoutUserBusy,
  logoutUserError,
  logoutUserSuccess,
  removeUserBusy,
  removeUserError,
  removeUserSuccess,
  updateUserBusy,
  updateUserError,
  updateUserSuccess,
  VALIDATE_USER,
  validateUserBusy,
  validateUserError,
  validateUserSuccess,
} from "./action";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { login, logout, readUser, removeUser, updateUser, validate } from "./api";

import { ActionTypes, clearError } from "../util";
import { BUSY_GLOBAL } from "../busy/action";
import _ from "lodash";
import { logError } from "utils/utils";
import { reset } from "../action";

const { REQUEST } = ActionTypes;

const defaultPreferences = (user) => {
  // TODO declare default preferences in second object
  user.preferences = _.merge({}, {}, user.preferences);
  return user;
};

export function* readUserSaga() {
  try {
    yield put(fetchUserBusy(true));
    yield put(fetchUserError());
    let response = yield call(readUser);
    response = yield call(defaultPreferences, response);
    yield put(fetchUserSuccess(response));
  } catch (error) {
    logError(error);
    yield put(fetchUserError(error.message));
  } finally {
    yield put(fetchUserBusy(false));
  }
}

export function* removeUserSaga() {
  try {
    yield put(removeUserBusy(BUSY_GLOBAL));
    yield put(removeUserError());
    yield call(removeUser);
    yield put(removeUserSuccess(true));
  } catch (error) {
    logError(error);
    yield put(removeUserError(error.message));
  } finally {
    yield put(removeUserBusy(false));
  }
}

export function* updateUserSaga(action) {
  const { email, password, preferences } = action.payload;
  try {
    yield put(updateUserBusy(BUSY_GLOBAL));
    yield put(updateUserError());
    const response = yield call(updateUser, email, password, preferences);
    yield put(updateUserSuccess(response));
  } catch (error) {
    logError(error);
    yield put(updateUserError(error.message));
  } finally {
    yield put(updateUserBusy(false));
  }
}

export function* loginSaga(action) {
  const { email, password } = action.payload;
  try {
    yield put(loginUserBusy(BUSY_GLOBAL));
    yield put(loginUserError());
    const response = yield call(login, email, password);
    yield put(loginUserSuccess(response));
    yield call(validateSaga);
  } catch (error) {
    logError(error);
    yield put(loginUserError(error.message));
  } finally {
    yield put(loginUserBusy(false));
  }
}

export function* logoutSaga() {
  try {
    yield put(logoutUserBusy(BUSY_GLOBAL));
    yield put(logoutUserError());
    yield call(logout);
    yield put(reset());
    yield put(logoutUserSuccess(true));
  } catch (error) {
    logError(error);
    yield put(logoutUserError(error.message));
  } finally {
    yield put(logoutUserBusy(false));
  }
}

export function* validateSaga() {
  try {
    yield put(validateUserBusy(BUSY_GLOBAL));
    yield put(validateUserError());
    const response = yield call(validate);
    yield put(validateUserSuccess(response));
    yield call(readUserSaga);
  } catch (error) {
    logError(error);
    yield put(clearError(validateUserError(error.message)));
  } finally {
    yield put(validateUserBusy(false));
  }
}

export function* continueSaga() {
  try {
    yield put(continueUserBusy(BUSY_GLOBAL));
    yield put(continueUserError());
    yield call(validateSaga);
  } catch (error) {
    logError(error);
    yield put(clearError(continueUserError(error.message)));
  } finally {
    yield put(continueUserBusy(false));
  }
}

export default function* usersSaga() {
  yield takeLatest(CONTINUE_USER[REQUEST], continueSaga);
  yield takeLatest(FETCH_USER[REQUEST], readUserSaga);
  yield takeEvery(REMOVE_USER[REQUEST], removeUserSaga);
  yield takeEvery(UPDATE_USER[REQUEST], updateUserSaga);
  yield takeLatest(LOGIN_USER[REQUEST], loginSaga);
  yield takeLatest(LOGOUT_USER[REQUEST], logoutSaga);
  yield takeLatest(VALIDATE_USER[REQUEST], validateSaga);
}
