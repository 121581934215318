import React, { Fragment } from "react";
import mixin from "utils/mixin";
import _ from "lodash";
import { MuiSection, MuiButton } from "components";
import clsx from "clsx";
import { Paper, Grid, Typography } from "@material-ui/core";
import {
  days,
  compressors,
  economizers,
  fans,
  heatings,
  buildings,
  windows,
  orientations,
  climates,
  zones,
  Upgraded,
  Baseline,
} from "rtucc-common/constants";
import { active, black, light, lightest, white } from "constants/palette";
import Plot from "react-plotly.js";
import { AutoSizer } from "react-virtualized";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Analytics } from "utils/analytics";
import stages from "rtucc-common/constants/stages";

class ResultView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    _.merge(this, mixin);
  }

  componentDidMount() {
    const { stage } = this.props;
    Analytics.getInstance().event(`Result ${_.get(stages.parse(stage), "label", "Unknown")} View`, "Opened");
  }

  getResultValue = (field) => {
    const { calculation } = this.props;
    const value = _.get(calculation, ["result", field], NaN);
    return value === null ? "None" : value;
  };

  stringifyResult = (calculation, type) => {
    let keys;
    switch (type) {
      case Baseline:
        keys = ["base_idf", "base_err"];
        break;
      case Upgraded:
        keys = ["upgrade_idf", "upgrade_err"];
        break;
      default:
        throw new Error(`Unhandled enumeration ${type} passed to switch.`);
    }
    return _.get(calculation, ["result", keys[0]], _.get(calculation, ["result", keys[1]], [])).join("\n");
  };

  handleDownloadPng = () => {
    const captureElement = document.querySelector("#capture");
    html2canvas(captureElement)
      .then((canvas) => {
        canvas.style.display = "none";
        canvas.style["background-color"] = white;
        document.body.appendChild(canvas);
        return canvas;
      })
      .then((canvas) => {
        const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        const a = document.createElement("a");
        a.setAttribute("download", "download.png");
        a.setAttribute("href", image);
        a.click();
        canvas.remove();
      });
  };

  handleDownloadPdf = () => {
    const captureElement = document.querySelector("#capture");
    html2canvas(captureElement)
      .then((canvas) => {
        canvas.style.display = "none";
        canvas.style["background-color"] = white;
        document.body.appendChild(canvas);
        return canvas;
      })
      .then((canvas) => {
        const width = parseInt(_.get(/width ?: ?"?([0-9]+)/i.exec(canvas.outerHTML), ["1"], "0"));
        const height = parseInt(_.get(/height ?: ?"?([0-9]+)/i.exec(canvas.outerHTML), ["1"], "0"));
        const image = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          format: [width, height],
        });
        pdf.addImage(image, "PNG", 0, 0, width, height);
        pdf.save("download.pdf");
        canvas.remove();
      });
  };

  handleDownloadIdf = (calculation, type) => {
    const element = document.createElement("a");
    const file = new Blob([this.stringifyResult(calculation, type)], { type: "application/idf" });
    element.href = URL.createObjectURL(file);
    element.download = `${type}.idf`;
    document.body.appendChild(element);
    element.click();
  };

  renderDownload() {
    const { classes, calculation } = this.props;
    return (
      <Paper className={clsx(classes.content, classes.formGroup)}>
        <MuiButton
          className={classes.formEntry}
          key="download-png"
          color="primary"
          onClick={() => this.handleDownloadPng()}
          variant="primary"
          type="submit"
        >
          Download PNG
        </MuiButton>
        <MuiButton
          className={classes.formEntry}
          key="download-pdf"
          color="primary"
          onClick={() => this.handleDownloadPdf()}
          variant="primary"
          type="submit"
        >
          Download PDF
        </MuiButton>
        <MuiButton
          className={classes.formEntry}
          key="download-baseline"
          color="primary"
          onClick={() => this.handleDownloadIdf(calculation, Baseline)}
          variant="primary"
          type="submit"
        >
          Download Baseline IDF
        </MuiButton>
        <MuiButton
          className={classes.formEntry}
          key="download-upgraded"
          color="primary"
          onClick={() => this.handleDownloadIdf(calculation, Upgraded)}
          variant="primary"
          type="submit"
        >
          Download Upgraded IDF
        </MuiButton>
      </Paper>
    );
  }

  renderIdf() {
    const { classes, calculation } = this.props;
    return (
      <Paper className={clsx(classes.content, classes.formGroup)}>
        <MuiButton
          className={classes.formEntry}
          key="download-baseline"
          color="primary"
          onClick={() => this.handleDownloadIdf(calculation, Baseline)}
          variant="primary"
          type="submit"
        >
          Download Baseline IDF
        </MuiButton>
        <MuiButton
          className={classes.formEntry}
          key="download-upgraded"
          color="primary"
          onClick={() => this.handleDownloadIdf(calculation, Upgraded)}
          variant="primary"
          type="submit"
        >
          Download Upgraded IDF
        </MuiButton>
      </Paper>
    );
  }

  renderRow(...items) {
    return (
      <Fragment key={`row-${items[0]}`}>
        {items.map((item, i) => {
          if (i === 0 || !_.isEmpty(item)) {
            const slots =
              i < items.length - 1 && _.isEmpty(items[i + 1])
                ? 1 + items.slice(i + 1).filter((v) => _.isEmpty(v)).length
                : 1;
            return (
              <Grid key={`row-${i}`} item xs={Math.floor(12 / items.length) * slots}>
                <Typography>{item}</Typography>
              </Grid>
            );
          }
          return undefined;
        })}
      </Fragment>
    );
  }

  renderCalculationDetails() {
    const { calculation } = this.props;
    return (
      <MuiSection header="" bordered>
        <Grid container alignItems="center" alignContent="center" justifyContent="flex-start" spacing={2}>
          {[
            this.renderRow("Label:", _.get(calculation, ["label"]), "", ""),
            this.renderRow("Building Type:", buildings.parse(_.get(calculation, ["building"])).label, "", ""),
            this.renderRow("Zone Type:", zones.parse(_.get(calculation, ["zone"])).label, "", ""),
            this.renderRow("Window Area:", windows.parse(_.get(calculation, ["window"])).label, "", ""),
            this.renderRow(
              "Building Orientation:",
              orientations.parse(_.get(calculation, ["orientation"])).label,
              "",
              ""
            ),
            this.renderRow(
              "Climate (City/State):",
              `${climates.parse(_.get(calculation, ["climate"])).label} - ${climates.findLocation(
                _.get(calculation, ["climate"])
              )} (${climates.findDescription(_.get(calculation, ["climate"]))})`,
              "",
              ""
            ),
            this.renderRow("Cooling Set Point:", `${_.get(calculation, ["coolingSetpoint"])} °F`, "", ""),
            this.renderRow("Cooling Setback:", `${_.get(calculation, ["coolingSetback"])} °F`, "", ""),
            this.renderRow("Heating Set Point:", `${_.get(calculation, ["heatingSetpoint"])} °F`, "", ""),
            this.renderRow("Heating Setback:", `${_.get(calculation, ["heatingSetback"])} °F`, "", ""),
            this.renderRow("Oversizing Factor:", `${_.get(calculation, ["oversizeFactor"])} x`, "", ""),
            this.renderRow("Cooling Capacity:", `${_.get(calculation, ["coolingCapacity"])} Btu/h`, "", ""),
            this.renderRow(
              "Blended Electric Utility Rate:",
              `$${_.get(calculation, ["blendedElectricityRate"])} / kWh`,
              "",
              ""
            ),
            this.renderRow(
              "Blended Natural Gas Rate:",
              `$${_.get(calculation, ["blendedNaturalGasRate"])} / ccf`,
              "",
              ""
            ),
            this.renderRow("Discount Rate:", `${_.get(calculation, ["realDiscountRate"])} %`, "", ""),
            this.renderRow("Equipment Life:", `${_.get(calculation, ["rtuLifetime"])} years`, "", ""),
            this.renderRow(
              "Capital Cost Baseline Unit:",
              `$${parseInt(_.get(calculation, ["capitalCostBaseline"])).toLocaleString()}`,
              "",
              ""
            ),
            this.renderRow(
              "Capital Cost Upgrade Unit:",
              `$${parseInt(_.get(calculation, ["capitalCostUpgraded"])).toLocaleString()}`,
              "",
              ""
            ),
          ]}
        </Grid>
      </MuiSection>
    );
  }

  renderCompressorDetails() {
    const { calculation } = this.props;
    return (
      <MuiSection header="" bordered>
        <Grid container alignItems="center" alignContent="center" justifyContent="flex-start" spacing={2}>
          {[
            this.renderRow("", "Baseline Unit", "Upgraded Unit"),
            this.renderRow(
              "Compressor Speed:",
              compressors.parse(_.get(calculation, ["compressorBaseline"])).label,
              compressors.parse(_.get(calculation, ["compressorUpgraded"])).label
            ),
            this.renderRow(
              "Number of Compressor Speeds:",
              `${_.get(calculation, ["numberOfSpeedsBaseline"])}`,
              `${_.get(calculation, ["numberOfSpeedsUpgraded"])}`
            ),
            this.renderRow(
              "Energy Efficiency Ratio:",
              `${_.get(calculation, ["eerBaseline"])}:1`,
              `${_.get(calculation, ["eerUpgraded"])}:1`
            ),
            this.renderRow(
              "Fan Type:",
              fans.parse(_.get(calculation, ["fanBaseline"])).label,
              fans.parse(_.get(calculation, ["fanUpgraded"])).label
            ),
            this.renderRow(
              "Heating Equipment:",
              heatings.parse(_.get(calculation, ["heatingBaseline"])).label,
              heatings.parse(_.get(calculation, ["heatingUpgraded"])).label
            ),
            this.renderRow(
              "Heating Efficiency:",
              `${_.get(calculation, ["heatingCopBaseline"])}:1`,
              `${_.get(calculation, ["heatingCopUpgraded"])}:1`
            ),
            this.renderRow(
              "Outdoor Air Fraction:",
              `${_.get(calculation, ["minOaFractionBaseline"])}`,
              `${_.get(calculation, ["minOaFractionUpgraded"])}`
            ),
            this.renderRow(
              "Economizer:",
              economizers.parse(_.get(calculation, ["economizerBaseline"])).label,
              economizers.parse(_.get(calculation, ["economizerUpgraded"])).label
            ),
            this.renderRow(
              "Economizer High Limit:",
              `${_.get(calculation, ["maximumLimitDryBulbBaseline"])} °F`,
              `${_.get(calculation, ["maximumLimitDryBulbUpgraded"])} °F`
            ),
          ]}
        </Grid>
      </MuiSection>
    );
  }

  renderScheduleDetails() {
    const { calculation } = this.props;
    return (
      <MuiSection header="" bordered>
        <Grid container alignItems="center" alignContent="center" justifyContent="flex-start" spacing={2}>
          {[
            this.renderRow("", "Start Time", "Stop Time"),
            ...days
              .values()
              .map((day) =>
                this.renderRow(
                  day.label,
                  `${_.get(calculation, ["Schedule", `${day.name}Start`])} AM`,
                  `${_.get(calculation, ["Schedule", `${day.name}End`]) - 12} PM`
                )
              ),
          ]}
        </Grid>
      </MuiSection>
    );
  }

  renderResultDetails() {
    const { classes } = this.props;
    const annualElectricityConsumption =
      this.getResultValue("AnnualElectricityConsumption_Baseline") -
      this.getResultValue("AnnualElectricityConsumption_Upgrade");
    const annualGasConsumption =
      this.getResultValue("AnnualGasConsumption_Baseline") - this.getResultValue("AnnualGasConsumption_Upgrade");
    const annualElectricityCost =
      this.getResultValue("AnnualElectricityCost_Baseline") - this.getResultValue("AnnualElectricityCost_Upgrade");
    const annualGasCost =
      this.getResultValue("AnnualNaturalGasCost_Baseline") - this.getResultValue("AnnualNaturalGasCost_Upgrade");
    const annualCosts = this.getResultValue("AnnualCosts_Baseline") - this.getResultValue("AnnualCosts_Upgrade");
    // other savings
    const llc = this.getResultValue("LCC_baseline") - this.getResultValue("LCC_upgrade");
    const annualizedCost =
      this.getResultValue("AnnualizedCost_Baseline") - this.getResultValue("AnnualizedCost_Upgrade");
    // net present value removed
    // const netPresentValue = this.getResultValue("NPV");
    const rateOfReturn = this.getResultValue("RateOfReturn");
    // payback
    let payback = this.getResultValue("Payback");
    if (payback === Infinity || payback === "Infinity") {
      payback = "Payback greater than the unit life";
    } else if (_.isFinite(payback)) {
      payback = `${this.getResultValue("Payback")} year${this.getResultValue("Payback") === 1 ? "" : "s"}`;
    } else {
      payback = `${payback}`;
    }
    return (
      <MuiSection header="" bordered>
        <Grid container alignItems="center" alignContent="center" justifyContent="flex-start" spacing={2}>
          {[
            this.renderRow("", Baseline, Upgraded, "Savings"),
            this.renderRow(
              "Annual Electricity Consumption",
              `${this.getResultValue("AnnualElectricityConsumption_Baseline").toLocaleString()} kWh`,
              `${this.getResultValue("AnnualElectricityConsumption_Upgrade").toLocaleString()} kWh`,
              <span className={clsx(annualElectricityConsumption < 0 && classes.negative)}>
                {annualElectricityConsumption.toLocaleString()} kWh
              </span>
            ),
            this.renderRow(
              "Annual Natural Gas Consumption",
              `${this.getResultValue("AnnualGasConsumption_Baseline").toLocaleString()} ccf`,
              `${this.getResultValue("AnnualGasConsumption_Upgrade").toLocaleString()} ccf`,
              <span className={clsx(annualGasConsumption < 0 && classes.negative)}>
                {annualGasConsumption.toLocaleString()} ccf
              </span>
            ),
            this.renderRow(
              "Annual Electricity Cost",
              `$${this.getResultValue("AnnualElectricityCost_Baseline").toLocaleString()}`,
              `$${this.getResultValue("AnnualElectricityCost_Upgrade").toLocaleString()}`,
              <span className={clsx(annualElectricityCost < 0 && classes.negative)}>
                ${annualElectricityCost.toLocaleString()}
              </span>
            ),
            this.renderRow(
              "Annual Natural Gas Cost",
              `$${this.getResultValue("AnnualNaturalGasCost_Baseline").toLocaleString()}`,
              `$${this.getResultValue("AnnualNaturalGasCost_Upgrade").toLocaleString()}`,
              <span className={clsx(annualGasCost < 0 && classes.negative)}>${annualGasCost.toLocaleString()}</span>
            ),
            this.renderRow(
              "Annual Operating Cost",
              `$${this.getResultValue("AnnualCosts_Baseline").toLocaleString()}`,
              `$${this.getResultValue("AnnualCosts_Upgrade").toLocaleString()}`,
              <span className={clsx(annualCosts < 0 && classes.negative)}>${annualCosts.toLocaleString()}</span>
            ),
            this.renderRow(
              "Life Cycle Cost",
              `$${this.getResultValue("LCC_baseline").toLocaleString()}`,
              `$${this.getResultValue("LCC_upgrade").toLocaleString()}`,
              <span className={clsx(llc < 0 && classes.negative)}>${llc.toLocaleString()}</span>
            ),
            this.renderRow(
              "Annualized Cost",
              `$${this.getResultValue("AnnualizedCost_Baseline").toLocaleString()}`,
              `$${this.getResultValue("AnnualizedCost_Upgrade").toLocaleString()}`,
              <span className={clsx(annualizedCost < 0 && classes.negative)}>${annualizedCost.toLocaleString()}</span>
            ),
            // this.renderRow(
            //   <span>&nbsp;</span>,
            //   "",
            //   "",
            //   <span
            //     className={clsx(netPresentValue < 0 && classes.negative)}
            //   >{`$${netPresentValue.toLocaleString()}`}</span>
            // ),
            this.renderRow("Payback", "", "", payback),
            this.renderRow("Rate of Return", "", "", `${rateOfReturn}${_.isFinite(rateOfReturn) ? "%" : ""}`),
            this.renderRow("Savings to Investment Ratio", "", "", `${this.getResultValue("SIR")}`),
          ]}
          <Grid item xs={12}>
            {this.renderChart()}
          </Grid>
        </Grid>
      </MuiSection>
    );
  }

  renderChart() {
    const { calculation } = this.props;
    const baseline = _.get(calculation, ["result", "DiscountedCostsCumulative_baseline"], []);
    const upgraded = _.get(calculation, ["result", "DiscountedCostsCumulative_upgrade"], []);
    const interval = _.get(calculation, ["result", "DiscountedCostsCumulative_interval"], 1.0);
    const years = _.get(calculation, ["result", "DiscountedCostsCumulative_year"]);
    const pad = parseInt(Math.max(_.max(baseline), _.max(upgraded))).toLocaleString().length;
    return (
      <div style={{ height: "600px" }}>
        <AutoSizer>
          {({ width, height }) => (
            <Plot
              layout={{
                width: width,
                height: height,
                title: "",
                showlegend: true,
                plot_bgcolor: lightest,
                paper_bgcolor: lightest,
                margin: {
                  pad: 8,
                  l: 40 + pad * 8.5,
                },
                xaxis: {
                  title: "System Life (years)",
                  showgrid: true,
                  zeroline: true,
                  showline: true,
                  linecolor: light,
                  linewidth: 4,
                },
                yaxis: {
                  title: "Discounted Costs ($)",
                  tickformat: "$,.0f",
                  showgrid: true,
                  zeroline: true,
                  showline: true,
                  linecolor: light,
                  linewidth: 4,
                },
              }}
              config={{
                displaylogo: false,
              }}
              data={[
                {
                  x:
                    _.isArray(years) && years.length === baseline.length ? years : baseline.map((v, i) => i * interval),
                  y: baseline.map((v) => v),
                  legendgroup: Baseline,
                  showlegend: true,
                  hoverinfo: "skip",
                  name: Baseline,
                  type: "scatter",
                  mode: "lines",
                  line: { shape: "spline", color: active, size: 3, width: 3 },
                  hovertemplate: "%{y:$,.0f}",
                },
                {
                  x:
                    _.isArray(years) && years.length === upgraded.length ? years : upgraded.map((v, i) => i * interval),
                  y: upgraded.map((v) => v),
                  legendgroup: Upgraded,
                  showlegend: true,
                  hoverinfo: "skip",
                  name: Upgraded,
                  type: "scatter",
                  mode: "lines",
                  line: { shape: "spline", color: black, size: 3, width: 3 },
                  hovertemplate: "%{y:$,.0f}",
                },
              ]}
            />
          )}
        </AutoSizer>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        {this.renderDownload()}
        <div className={classes.padding} />
        <Paper id="capture" className={clsx(classes.content)}>
          <Typography variant="h4">Rooftop Unit Comparison Calculator Results</Typography>
          <div className={classes.padding} />
          {this.renderCalculationDetails()}
          <div className={classes.padding} />
          <Typography variant="h5">Key Details of the Baseline and Upgraded Unit</Typography>
          <div className={classes.padding} />
          {this.renderCompressorDetails()}
          <div className={classes.padding} />
          <Typography variant="h5">Schedule for the Unit Operations</Typography>
          <div className={classes.padding} />
          {this.renderScheduleDetails()}
          <div className={classes.padding} />
          <Typography variant="h5">Results</Typography>
          <div className={classes.padding} />
          {this.renderResultDetails()}
        </Paper>
      </Fragment>
    );
  }
}

export default ResultView;
