import { SnackbarContent, withStyles } from "@material-ui/core";
import React, { Fragment } from "react";
import _ from "lodash";
import clsx from "clsx";
import { Analytics } from "utils/analytics";
import styles from "./styles";
import Overview from "./Overview";

class ErrorView extends React.Component {
  componentDidMount() {
    Analytics.getInstance().event(`Error View`, "Opened");
  }

  render() {
    const { classes, calculation } = this.props;
    const message = _.get(calculation, ["error"], "Error message not found.");
    return (
      <Fragment>
        <Overview {...this.props} />
        <div className={classes.padding} />
        <div className={clsx(classes.formGroup)}>
          <SnackbarContent
            className={clsx(classes.snackbar, classes.formEntry)}
            message={
              <span id="client-snackbar" className={classes.message}>
                RTUCC calculate failed:
                <br />
                {message}
              </span>
            }
          />
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(ErrorView);
