import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { MuiButton, MuiIconButton } from "components";

import { Close as CloseIcon } from "@material-ui/icons";
import { ConditionalWrapper } from "utils/utils";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";

/**
 * Abstracted dialog
 * buttons - (Array<Object>),
 * * label - (String): Text to be displayed
 * * onClick - (function): action when button is clicked
 * * variant - (String) [cancel, error, info, outlined, primary, warning]: button variant
 * open - (Boolean): Show / Hide dialog
 * title - (String): Title of dialog
 */
class MuiDialog extends React.Component {
  static defaultProps = {
    open: false,
  };

  handleClick = (button) => () => {
    if (button.onClick != null) {
      button.onClick();
    }
  };

  isForm = () => {
    const { buttons } = this.props;
    return Boolean(_.find(buttons, { type: "submit" }));
  };

  handleSubmit = () => {
    const { buttons } = this.props;
    const button = _.find(buttons, { type: "submit" });
    button.onClick(button);
  };

  render() {
    const {
      classes,
      title,
      children,
      buttons,
      open,
      busy,
      maxWidth,
      disablePadding,
    } = this.props;
    return (
      <Dialog
        fullWidth={true}
        maxWidth={maxWidth ? maxWidth : "xs"}
        open={open}
        aria-labelledby="form-dialog-title"
      >
        <ConditionalWrapper
          condition={this.isForm()}
          wrapper={(c) => (
            <form id={"form-dialog"} onSubmit={this.handleSubmit}>
              {c}
            </form>
          )}
        >
          <DialogTitle
            disableTypography
            className={classes.header}
            id="form-dialog-title"
            aria-label={title}
          >
            <Typography className={classes.title}>
              <strong>{title}</strong>
            </Typography>
            <div className={classes.icon}>
              {Boolean(this.props.onClose) ? (
                <MuiIconButton aria-label="Close" onClick={this.props.onClose}>
                  <CloseIcon />
                </MuiIconButton>
              ) : null}
            </div>
          </DialogTitle>
          <DialogContent
            className={disablePadding ? classes.form : classes.content}
          >
            {children}
          </DialogContent>
          <DialogActions className={classes.footer}>
            {busy ? <CircularProgress /> : null}
            {buttons &&
              buttons.map((button) => (
                <div
                  key={`dialog-div-${button.label}`}
                  className={classes.button}
                >
                  <MuiButton
                    key={`dialog-button-${button.label}`}
                    color="primary"
                    form="form-dialog"
                    onClick={this.handleClick(button)}
                    disabled={button.disabled}
                    variant={button.variant}
                    type={button.type}
                  >
                    {button.label}
                  </MuiButton>
                </div>
              ))}
          </DialogActions>
        </ConditionalWrapper>
      </Dialog>
    );
  }
}

MuiDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  children: PropTypes.element.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.object.isRequired),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  maxWidth: PropTypes.string,
  disablePadding: PropTypes.bool,
};

export default withStyles(styles)(MuiDialog);
